@import "styles/mixins/index";
.product-preview-section-theme2 {
  padding: map-get($pixel, md) * 7 0 0 0;
  font-family: var(--text-font-family);
  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 4 0 0 0;
  }
  .grid {
    // @include grid  -layout;
    display: flex;
    grid-template-columns: max(530px) minmax(0, 1fr);
    // gap: 0 map-get($pixel, md) * 10 + 7px;
    gap: 0;
    // padding: 0 70px;
    margin-bottom: 30px;
    @include breakpoint("max-md") {
      display: flex;
      gap: 40px;
      padding: 0;
      grid-template-columns: none;
      padding: 0;
    }
    .grid-width {
      width: 41.666667%;
    }
    .desc-items{
      &.grid-width{
        width: 58.333333%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    } 
    @media (min-width: 1920px) {}
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      display: block;
    }
    @include breakpoint("max-sm") {
      display: block;
      grid-template-columns: none;
    }
    .grid-items:nth-child(2) {
      padding: map-get($pixel, md) * 4 0 0 0;
      @include breakpoint("max-sm") {
        padding: map-get($pixel, md) * 2 0 0 0;
      }
    }
    .grid-items {
      @include breakpoint("max-md") {
        width: 50%;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .product-title-sec {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        h1 {
          font-family: var(--text-font-family);
          align-items: center;
          color: #000;
          font-size: 22px;
          font-weight: 600;
          @media (min-width: 767px) {
            max-width: 80%;
          }
          @include breakpoint("max-md") {
            @include heading-7;
            @include font-medium;
            font-size: 18px;
          }
        }
        .stock-lable {
          border-radius: 5px;
          font-size: 18px;
          font-weight: 400;
          padding: 3px 5px;
          height: max-content;
          background: #20b52633;
          color: var(--bg-color-theme2);
        }
      }
      .coupon-pdp-used-time-wrapper {
        .flex-coupon{
          display: flex;
          align-items: center;
          position: relative;
          align-items: center;
          margin: 0 0 10px 0;
          padding: 10px 0 0 0;
          border-top: 1px solid #e6e6e6;
          // align-items: center;
          @media (max-width: 450px) {
            flex-wrap: wrap;
          }
        }
        .offer-price {
          display: inline-block;
          .offer-text {
            display: inline-block;
            margin-left: 20px;
            @media (max-width: 450px) {
              margin: 0px 0 0 10px;
            }
          }
        }
        .coupon-used-pdp-item {
          margin-top: 6px;
        }
        .coupon-design {
          .offer-text {
            display: inline-block;
          }
          background-color: var(--secondary-color);
          position: relative;
          width: -moz-fit-content;
          width: fit-content;
          cursor: pointer;
          border: none;
          border-radius: 5px;
          padding: 5px 10px;
          p {
            color: var(--bg-white);
            @include heading-4;
            @include breakpoint("max-md") {
              font-size: 12px;
              line-height: 16px;
            }
            span {
              @include font-medium;
            }
          }
        }
        .coupon-details {
          padding: 7px 34px 7px 18px;
          margin: 0 40px map-get($pixel, md) * 3 + 5px 0;
          position: absolute;
          width: fit-content;
          @include breakpoint("max-sm") {
            margin: 0 0 10px -2px;
          }
          @include breakpoint("max-md") {
            margin: 0 50px map-get($pixel, md) * 2 0;
          }
        }
      }
      .coupon-title {
        padding: 0 0 10px;
        display: inline-block;
        color: #3a3a3a;
        font-size: 14px;
      }
      .final-price {
        h2 {
          color: var(--black-5-light);
          font-weight: 500;
        }
        span {
          color: var(--primary-color);
        }
      }
      .department-code {
        @include flex-center;
        padding-top: 10px;
        div:first-child {
          padding-right: map-get($pixel, md) * 4;
        }
        + {
          .other-price-details,
          .coupon-title {
            padding-top: 10px;
          }
        }
        div {
          p {
            @include text-sm-normal;
            color: #3a3a3a;
            font-family: var(--text-font-family);
            font-weight: 500;
            span {
              color: #808080;
              font-weight: 400;
            }
          }
        }
      }
      .restricted {
        p {
          color: var(--bg-danger);
        }
      }
      .substitution {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        p {
          font-size: 14px;
          color: #3a3a3a;
          margin-right: 10px;
        }
        .substitution-border {
          border: 1px solid var(--light-border-color) !important;
        }
        .select-substitition-dropdown {
          position: relative;
          width: fit-content;
          .show-items {
            margin: 0;
          }
          button {
            background: #fff;
            font-family: var(--text-font-family);
            border: 1px solid #ededed;
            color: #000;
            border-radius: 30px;
            display: block;
            font-size: 14px;
            padding: 10px 12px;
            text-align: left;
            text-align: center;
            display: inline-block;
            width: 100%;
            transition: all 0.5s ease-in-out;
            &:hover {
              background-color: #000;
              color: #fff;
              img {
                filter: brightness(11);
              }
            }
            span {
              width: auto;
              overflow: unset;
              font-family: var(--text-font-family-theme2);
              display: inline-block;
              padding: 0 8px 0 0;
            }
            img {
              display: inline-block;
            }
          }
          .substitution-dropdown {
            position: absolute;
            width: 100%;
            .list-menu {
              border: none;
            }
          }
        }
      }
      .add-product-option {
        @include flex-center;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        padding: 20px 0 20px 0;
        justify-content: space-between;
        @include breakpoint("max-md") {
          flex-wrap: wrap;
          gap: 10px;
        }
        @include breakpoint("max-sm") {
          display: flex;
          flex-wrap: wrap;
        }
        .button-disable {
          pointer-events: none;
          opacity: 0.5;
        }
        .link-button {
          position: relative;
          margin-left: map-get($pixel, md);
          @include breakpoint("max-md") {
            margin-left: 0;
          }
          @include breakpoint("max-sm") {
            margin-left: 0;
          }
          button {
            width: 137px;
            height: 50px;
            @include flex-center;
            justify-content: map-get($layout, center);
            border-radius: 5px;
            background-color: transparent;
            border: 1px solid #ccc;
            i {
              color: #ccc;
            }
          }
          .fa-solid {
            color: red;
          }
          .offer-product-list-icon {
            .add-to-list-icon {
              border: 1px solid #bfbfbf;
              background-color: #fff;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
              -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
              border-radius: 50%;
              display: inline-block;
              line-height: 2.8;
              width: 35px;
              height: 35px;
              text-align: center;
              cursor: pointer;
              transition: all 0.5s ease-in-out;
              &:hover {
                background-color: var(--bg-color-theme2);
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
              .list-tooltip {
                position: absolute;
                bottom: auto;
                display: inline-block;
                padding: 3px 6px;
                color: #000;
                font-size: 12px;
                z-index: 1;
                line-height: normal;
                left: -23px;
                white-space: nowrap;
                right: 0;
                top: -28px;
                margin: auto;
                width: max-content;
                background-color: #fff;
                border-radius: 4px;
                display: none;
                box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
              }
              &:hover .list-tooltip {
                display: block;
              }
            }
          }
          .offer-product-list-icon {
            position: relative;
            .add-to-list-theme2-dropdown {
              left: -150px;
              right: auto;
              bottom: 50px;
              .new-list-input-theme2-dropdown {
                top: -85%;
              }
            }
          }
        }
        .add-to-cart {
          button {
            margin: auto;
            background: var(--bg-color-theme2);
            border: none;
            text-transform: capitalize;
            border-radius: 20px;
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            padding: 9px 25px;
            text-align: center;
            color: #fff;
            font-family: var(--text-font-family-theme2);
            transition: all 0.5s ease-in-out;
            &:hover {
              background-color: #000;
              color: #fff;
              svg {
                path {
                  fill: #fff;
                }
              }
            }
            &:disabled {
              cursor: no-drop;
              opacity: 0.5;
              .add-to-cart-icon {
                display: none;
              }
              .out-of-stock-icon {
                display: inline-block;
                vertical-align: middle;
              }
            }
            .out-of-stock-icon {
              display: none;
            }
            span {
              padding-left: 5px;
              vertical-align: text-bottom;
            }
          }
        }
        .new-list-input-dropdown {
          @include breakpoint("max-sm") {
            right: -110px;
          }
        }
        .couter-button {
          @include breakpoint("max-sm") {
            padding-bottom: map-get($pixel, md);
          }
          button {
            border: 1px solid #f2f2f2;
            color: #fff;
            background-color: var(--border-color-theme2);
            border-radius: 50%;
            height: 35px;
            width: 35px;
            max-height: 35px;
            max-width: 35px;
            font-weight: 600;
            font-size: 31px;
            line-height: 0.7;
            transition: all 0.3s ease-in-out;
            @media (max-width: 480px) {
              padding: 0;
            }
            &:hover {
              background-color: #000;
              color: #fff;
            }
            &:disabled {
              cursor: default;
            }
            span {
              padding-left: 30px;
              display: block;
            }
            .arrow-right-side {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 14px;
              i {
                font-size: 12px;
                @include pointer;
              }
            }
          }
          .silder-right {
            align-items: center;
            border: 1px solid #e6e6e6;
            border-radius: 50px;
            display: flex;
            height: 45px;
            font-size: 21px;
            // flex-wrap: wrap;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 4px 8px;
            text-align: center;
            max-width: 110px;
            width: 110px;
            @media (max-width: 390px) {
              max-width: 140px;
            width: 140px;
            }
          }
        }
      }
      .other-price-details {
        p {
          padding-bottom: 5px;
        }
        p:first-child & p:last-child {
          padding: 0 0 map-get($pixel, md) * 2 0;
        }
      }
      .offer-price {
        span {
          margin-left: 20px;
          color: #1e1e1e;
          font-size: 20px;
          display: inline-block;
          font-weight: 600;
        }
      }
      .final-price {
        margin: 20px 0;
        @include flex-center;
        @include breakpoint("max-md") {
          display: block;
          margin: 10px 0;
        }
        .product-card-onsale-lable {
          display: inline-block;
          z-index: 1;
          font-size: 12px;
          position: relative;
          padding: 4px 4px 4px 15px;
          border-radius: 5px;
          margin: 0;
          font-weight: 600;
          background-color: var(--add-to-cart-sale-bg-color);
          color: var(--add-to-cart-sale-font-color);
          &::before {
            display: inline-block;
            width: 0;
            // height: 0; 
            border-style: solid;
            top: 0;
            bottom: 0;
            border-width: 12px 12px 12px 0;
            position: absolute;
            content: "";
            left: -1px;
            border-color: var(--bg-white) transparent var(--bg-white) transparent;
          }
        }
        .price {
          padding-right: map-get($pixel, md) * 3 + 4px;
          display: flex;
          align-items: center;
          .final-product-price {
            h2 {
              color: var(--text-color-theme2);
              font-weight: 500;
            }
          }
          .main-price {
            color: #808080;
            font-weight: 400;
            font-size: 24px;
            text-decoration: line-through;
          }
          h2 {
            font-size: 24px;
            margin: 0 20px 0 0;
          }
          img {
            height: 50px;
            width: 50px;
            margin-left: 12px
          }
        }
        .discount-price {
          p {
            @include text-sm-semi-bold;
            color: #fcbb0a;
            margin: 0;
          }
          span {
            @include text-4xl;
            display: block;
            color: var(--gray-8);
          }
        }
      }
      .product-category {
        color: #000;
        font-size: 16px;
        margin-bottom: 20px;
        p {
          span {
            color: #808080;
            font-weight: 600;
          }
        }
      }
      .rating-alignment {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 + 3px 0;
        .review-text {
          span {
            @include text;
            color: var(--gray-7);
          }
        }
        .star-alignment {
          @include flex-center;
          padding-right: map-get($pixel, sm) * 3 - 1px;
          i:last-child {
            color: #c2c2c2;
            margin: 0;
          }
          i {
            color: #f9c945;
            @include pointer;
            margin-right: map-get($pixel, sm);
          }
        }
      }
    }
    .grid-product-slider {
      .product-preview-slider-theme2 {
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
          margin-bottom: 30px;
        }
      }
    }
  }
}