@import "styles/mixins/index";

.edit-profile-section-theme2 {
  @include breakpoint("max-sm") {
    padding: 27px 0;
  }

  h1 {
    color: var(--invert-secondary-color);
    @include heading-2;
    margin: 0 0 map-get($pixel, md) * 2 + 6px 0;

    @include breakpoint("max-sm") {
      @include heading-3xl;
    }
  }

  .edit-profile-box {
    padding: 40px 47px 53px 47px;
    border: 1px solid var(--border-color-8);
    border-radius: 8px;

    @include breakpoint("max-sm") {
      padding: 25px;
    }

    .button-right-side {
      @include flex-center;
      justify-content: flex-end;

      div {
        button {
          min-width: 120px;
          height: 44px;
          border-radius: 4px;
          @include heading-4;
          border: none;
        }
      }

      div:first-child {
        margin-right: 15px;

        button {
          background-color: var(--bg-light-200);
          color: #989898;
        }
      }

      div:last-child {
        button {
          background-color: var(--primary-color);
          color: var(--bg-white);
        }
      }
    }

    .button-left-side {
      @include flex-center;
      justify-content: flex-start;

      div {
        button {
          min-width: 120px;
          height: 44px;
          border-radius: 4px;
          @include heading-4;
          border: none;
        }
      }

      div:first-child {
        margin-right: 15px;

        button {
          background-color: var(--bg-light-200);
          color: #989898;
        }
      }

      div:last-child {
        button {
          background-color: var(--secondary-color);
          color: var(--bg-white);
        }
      }
    }

    .two-col-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: map-get($pixel, sm) * 3;
      padding: 0 0 map-get($pixel, md) * 3 + 4px;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
      }

      .two-col-grid-items {
        h3 {
          @include heading-4;
          color: var(--primary-color);
          margin: 0 0 map-get($pixel, md) 0;
          line-height: 20px;
        }

        p {
          @include text-sm-normal;
          margin: 0;
          color: var(--black-3);
        }

        .form-control {
          position: relative;

          span {
            color: var(--bg-danger);
            font-size: 12px;
          }
        }

        .store-dropdown {
          position: absolute;
          width: 100%;
        }

        .down-icon-align {
          position: absolute;
          top: 50%;
          right: 16px;
          @include flex-center;
          transform: translateY(-50%);
          @include pointer;

          i {
            color: var(--gray-text-color);
          }
        }
      }
    }

    .text-grid {
      @include grid-layout;
      grid-template-columns: 50px minmax(0, 1fr);
      gap: 0 map-get($pixel, md) * 8;
      padding: 0 0 map-get($pixel, md) * 3 + 3px 0;

      @include breakpoint("max-md") {
        gap: 0;
      }

      .text-grid-items {
        p {
          @include heading-4;
          color: var(--invert-secondary-color);
          margin: 0;
        }

        span {
          @include heading-4;
          @include font-semi-bold;
          color: var(--invert-secondary-color);
          display: block;
        }
        .edit-email {
          background-color: var(--primary-color);
          color: var(--bg-white);
          border: none;
          padding: 8px 13px;
          border-radius: 4px;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  .select-dropdown {
    font-size: 14px;
    font-family: var(--heading-font-family), var(--default-font-family);
    padding: 11px 15px;
    cursor: pointer;

    font-weight: 400;
    color: var(--black-3);
    transition: 0.3s ease-in-out;
    line-height: 16px;
  }
  .feedback-success {
    display: block;
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  .feedback-error {
    display: block;
    margin-bottom: 10px;
    color: #fd1f00;
  }
}
