@import "styles/mixins/index";

.breadcrumb-detailed-section {
  background-color: var(--breadcrumb-bg-color);
  padding: 16px 50px;
  margin-bottom: 30px;
  @include breakpoint("max-sm") {
    padding: 12px 0;
  }
  @media (max-width: 650px) {
    display: none;
  }
  .text-alignment {
    @include flex-between;
    @include breakpoint("max-md") {
      display: block;
    }
    @include breakpoint("max-sm") {
      display: block;
    }
    .selected-child {
      color: #fff;
      @include flex-center;
      @include breakpoint("max-md") {
        flex-wrap: wrap;
        padding: 0;
      }
      @include breakpoint("max-sm") {
        flex-wrap: nowrap;
      }
      @include breakpoint("max-sm") {
        padding: 0;
      }
      button:first-child {
        color: var(--primary-color);
      }
      button:last-child {
        margin-right: map-get($pixel, sm) - map-get($pixel, sm);
        @include breakpoint("max-md") {
          display: none;
        }
      }
      button:nth-child(4) {
        display: none;
      }
      button {
        padding: map-get($pixel, sm) * 2 + 2px map-get($pixel, md) * 2;
        background-color: var(--bg-white);
        @include white-shaow;
        color: var(--gray-500);
        @include flex-center;
        margin-right: map-get($pixel, sm) * 4;
        @include heading-text-md-medium;
        border: none;
        border-radius: 999px;
        @include ease-in-out;
        @include breakpoint("max-md") {
          margin-bottom: map-get($pixel, sm) * 4;
          @include breakpoint("max-sm") {
            @include heading-6;
            margin-right: map-get($pixel, sm) * 3;
            line-height: 18px;
            padding: 12px 17px;
            margin-bottom: map-get($pixel, md);
          }
          img {
            @include breakpoint("max-sm") {
              width: 10px;
            }
          }
        }
        &:hover {
          color: var(--primary-color);
        }
        span {
          padding-left: map-get($pixel, sm) * 2 + 1px;
        }
      }
    }
    .breadcrumb-section {
      h1 {
        @include heading-large;
        color: var(--invert-secondary-color);
        // margin: 0 0 map-get($pixel, sm) * 3 + 5px 0;
        margin: 0;
        @include breakpoint("max-md") {
          @include heading-2;
          margin: 0 0 0 0;
        }
        @include breakpoint("max-sm") {
          @include heading-2;
        }
      }
      .breadcrumb-alignment {
        ul {
          @include flex-center;
          li:first-child {
            color: var(--breadcrumb-text-color);
            padding-left: 0px;
            i {
              padding-right: map-get($pixel, sm);
            }
          }
          li {
            @include text;
            color: var(--breadcrumb-sub-text-color);
            padding: 0 map-get($pixel, md);
            @include flex-center;
            @include pointer;
            @include breakpoint("max-sm") {
              font-size: 13px;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
}
.breadcrumb-section{
  .breadcrumb ul li:first-child i, .breadcrumb ul li:first-child{
    color: var(--breadcrumb-text-color) !important;
  }
}