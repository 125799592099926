.reward-view-main-wrap {
    .reward-view-box {
        text-align: left;
        .reward-view-img {
            @media (max-width: 992px) {
                text-align: center;
            }
            img {
                min-height: 100px;
                max-height: 100px;
                max-width: 100px;
            }
        }
        .reward-view-title {
            margin: 10px 0 8px;
            font-size: 20px;
            font-weight: 500;
            line-height: normal;
            @media (max-width: 1200px) {
                font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
            }
            @media (max-width: 992px) {
                margin-bottom: 10px;
            }
        }
        .view-reward-point {
            font-size: 18px;
            color: var(--primary-color);
            font-weight: 600;
        }
    }
}