@import "styles/mixins/index";
.home-product-search {
  margin: 20px 0;
  @media (max-width: 680px) {
    margin: 10px 0;
  }
  @media (min-width: 1350px) {
    display: none;
  }
  .search-product-bar {
    @media (max-width: 650px) {
      display: none;
    }
  }
  .mobile-menu {
    padding: 0 15px;
    @media (max-width: 650px) {
      display: none;
    }
    .mobile-categories {
      position: relative;
      border: 1px solid var(--light-border-color);
      border-radius: 4px;
      margin-bottom: 10px;
      .text {
        height: 46px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-family: var(--heading-font-family), var(--default-font-family);
          color: var(--gray-4);
          font-size: 14px;
          font-weight: 400;
        }
      }
      .dropdown-show {
        position: absolute;
        width: 100%;
      }
      .dropdown-hidden {
        transition: none;
      }
    }
    .input {
      margin: 0 0 map-get($pixel, md) * 3;
      @include breakpoint("max-sm") {
        margin: 0 0 map-get($pixel, md) * 2;
      }
      input {
        padding: 0 map-get($pixel, md) * 2;
      }
    }
    .mobile-search-products {
      width: map-get($pixel, w-full);
      margin: 0 0 20px;
      border: 1px solid var(--light-border-color);
      border-radius: 4px;
      .relative-div {
        position: relative;
        .icon-wrapper {
          position: absolute;
          top: 50%;
          right: 10px + 7px;
          transform: translateY(-50%);
        }
        >input {
          width: 100%;
          height: 46px;
          padding: 0 10px;
          background-color: transparent;
          transition: 0.2s ease-in-out;
          border: 1px solid transparent;
          @include heading-text-sm-normal;
          border-radius: 4px;
          color: var(--text-current);
          &:focus {
            border: 1px solid var(--primary-color);
          }
        }
        ::placeholder {
          @include heading-text-sm-normal;
          color: var(--text-current);
          @include font-normal;
        }
        .mobile-search-dropdown {
          width: 100%;
          z-index: 9999999;
          position: absolute;
        }
      }
    }
    .follow-us {
      padding: map-get($pixel, md) * 2 0 0 0;
      >p {
        @include heading-text-sm;
        color: var(--black-3);
        margin: 0 0 map-get($pixel, sm) * 3 + 1px 0;
      }
      .social-align {
        @include flex-center;
        div {
          width: 35px;
          height: 35px;
          @include flex-center;
          justify-content: map-get($layout, center);
          @include pointer;
          border-radius: 50%;
          background-color: var(--secondary-color);
          margin-right: map-get($pixel, sm) * 3 + 1px;
          i {
            color: var(--text-white);
          }
        }
      }
    }
    .action-box {
      margin: map-get($pixel, md) * 3 0 0 0;
      border: 1px solid var(--primary-color);
      padding: map-get($pixel, sm) * 3;
      border-radius: calc(5px - 1px);
      .continue-shopping-btn {
        button {
          margin-left: 0px;
          width: 100%;
        }
      }
      .modal-backdrop {
        // top: 260px;
        height: 140%;
      }
      .my-account-wrapper-mobile {
        position: relative;
        .my-account-dropdown {
          position: absolute;
          left: 28px;
          top: 54px;
          box-shadow: 0 0 15px #0000001a;
          width: 170px;
          z-index: 999999;
        }
        .my-account {
          padding: 15px 0;
          @include flex-center;
          position: relative;
          @include pointer;
          // @include breakpoint("max-md") {
          //   display: none;
          // }
          i {
            font-size: 25px;
          }
          div+div>span {
            @include heading-text-sm-normal;
            color: var(--black-text-color);
            display: block;
            padding-left: 7px;
          }
        }
        .my-account-profile-dropdown {
          background-color: var(--bg-white);
          padding: map-get($pixel, md) * 2;
          border-radius: map-get($pixel, sm) - 1px;
          border: 1px solid var(--light-border-color);
          div:last-child a {
            padding: 0;
          }
          >div>a {
            @include heading-text-sm-normal;
            color: var(--black-3);
            display: block;
            @include pointer;
            padding: 0 0 map-get($pixel, sm) * 3 0;
            @include ease-in-out;
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
      .need-help {
        @include heading-text-sm-normal;
        color: var(--gray-text-color);
        a {
          color: var(--secondary-color);
        }
      }
      .auth-links {
        padding: map-get($pixel, md) + 5px 0 map-get($pixel, md) 0;
        @include flex-center;
        div {
          :first-child {
            border-right: 1px solid var(--light-border-color);
            padding-right: 10px;
          }
        }
        a:last-child li {
          border: none;
        }
        div+div {
          margin-left: map-get($pixel, md);
        }
        a {
          @include heading-text-sm-normal;
          color: var(--black-3);
          @include pointer;
        }
      }
      .input {
        margin: 0;
        input {
          padding-left: 25px;
          border: none;
          border-radius: calc(5px - 5px);
          border-bottom: 1px solid var(--primary-color);
        }
        .left-icon-align {
          left: 0;
        }
      }
    }
    .menu-list {
      .icon-text-wrapper {
        padding: 10px;
        cursor: pointer;
        @include flex-between;
        width: 100%;
        span {
          font-size: 16px;
          line-height: 22px;
          font-family: var(--text-font-family), var(--default-font-family);
          font-weight: 600;
          color: var(--black-3);
        }
      }
      .sub-menu {
        a {
          padding-left: map-get($pixel, md) * 2 !important;
          @include ease-in-out;
          &:hover {
            color: var(--secondary-color) !important;
          }
        }
      }
      .sub-menu-show {
        padding-left: map-get($pixel, xl);
        transition: 0.3s ease-in-out;
      }
      .sub-menu-hidden {
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }
      a:last-child {
        border: none;
        padding-bottom: 0px;
      }
      .menu {
        a {
          @include text-semi-bold;
          color: var(--black-3);
          display: block;
          padding: map-get($pixel, md);
          border-bottom: 1px solid var(light-border-color);
          @include ease-in-out;
          width: 100%;
          display: block;
          &:hover {
            color: var(--secondary-color);
          }
        }
        .highlight-menu {
          color: var(--secondary-color);
        }
      }
    }
    .menu-list {
      .shop-by-department {
        margin-bottom: 0;
        .invert-button {
          color: var(--black-3);
          border: none;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          padding: 10px;
          line-height: 22px;
          margin: 0;
          font-family: var(--text-font-family), var(--default-font-family);
          font-weight: 600;
          img {
            margin: 0;
            min-width: 11px;
            min-height: 7px;
          }
        }
      }
    }
    .shop-by-department {
      position: relative;
      margin: 0 0 20px;
      .invert-button {
        width: 100%;
        display: flex;
        padding: 10px 13px;
        background-color: var(--invert-primary-color);
        color: var(--text-white);
        border: none;
        margin-right: 10px;
        height: 40px;
        cursor: pointer;
        border-radius: calc(5px - 1px);
        align-items: center;
        font-family: var(--heading-font-family), var(--default-font-family);
        font-weight: 400;
        justify-content: center;
        @include text-semi-bold;
        font-size: 14px;
        line-height: 16px;
        img {
          min-width: 9px;
          margin-right: 9px;
          vertical-align: middle;
        }
        >img {
          min-width: 9px;
          margin-right: 9px;
          vertical-align: middle;
        }
        >span {
          display: block;
        }
        svg {
          margin-left: 5px;
        }
      }
      .shop-by-department-menu {
        position: absolute;
        width: 100%;
        z-index: 99999;
        top: 45px;
        .shop-by-department-menu-content {
          border-radius: map-get($pixel, sm) + 3px;
          border: 1px solid var(--primary-color);
          background-color: var(--bg-white);
          overflow: hidden;
          overflow-y: auto;
          padding: map-get($pixel, md) * 2;
          max-height: 60vh;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 4px;
            background-color: var(--gray-9);
            border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: var(--primary-color);
          }
          .grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: map-get($pixel, sm) * 3;
            .grid-items {
              border: 1px solid var(--border-color);
              @include flex-center;
              border-radius: map-get($pixel, sm) - 1px;
              padding: map-get($pixel, md);
              @include pointer;
              @include ease-in-out;
              &:hover {
                border: 1px solid var(--primary-color);
              }
              .department-name {
                padding-left: map-get($pixel, lg);
                p {
                  @include heading-text-sm-normal;
                  color: var(--black-text-color);
                }
              }
              .department-icon {
                width: 36px;
                height: 36px;
                >img {
                  width: 36px;
                  height: 36px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
      .shop-by-department-menu-show {
        max-height: 100vh;
        overflow: hidden;
        @include ease-in-out;
      }
      .shop-by-department-menu-hidden {
        max-height: 0px;
        overflow: hidden;
        @include ease-in-out;
      }
    }
  }
  .search-product-bar {
    // margin: 0 15px;
    border: 1px solid var(--light-border-color);
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    @media (max-width: 680px) {
      height: 35px;
    }
    .mobile-categories {
      position: relative;
      .text {
        display: flex;
        align-items: center;
        padding-right: 10px;
        // border-right: 1px solid var(--light-border-color);
        position: relative;
        padding-left: 19px;
        height: 100%;
        min-width: 150px;
        justify-content: space-between;
        @media (max-width: 575px) {
          min-width: 100px;
          padding-left: 12px;
          font-size: 10px;
          img{
            margin-left: 3px;
          }
        }
      }
      .categories-menu {
        position: absolute;
        width: 100%;
        z-index: 9999;
        top: 45px;
        left: 0;
        // @media (max-width: 575px) {
        //  width: 200%;
        // }
      }
    }
    .categories {
      display: flex;
      align-items: center;
      padding-right: 10px;
      border-right: 1px solid var(--light-border-color);
      position: relative;
      padding-left: 19px;
      height: 100%;
      min-width: 200px;
      justify-content: space-between;
    }
    .mobile-search-products {
      width: map-get($pixel, w-full);
      border: 1px solid var(--light-border-color);
      border-radius: 4px;
      .relative-div {
        position: relative;
        .icon-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          // transform: translateY(-50%);
          .search-text {
            background-color: var(--bg-color-theme2);
            display: inline-block;
            padding: 12px 20px 10px;
            color: #fff;
            @media (max-width: 680px) {
              padding: 8px 6px 7px;
              font-size: 12px;
            }
          }
        }
        .search-dropdown {
          width: 100%;
          z-index: 9999999;
          position: absolute;
        }
        >input {
          width: 100%;
          height: 46px;
          padding: 0 0 0 40px;
          background-color: transparent;
          transition: 0.2s ease-in-out;
          border: 1px solid transparent;
          @include heading-text-sm-normal;
          border-radius: 4px;
          color: var(--text-current);
          @media (max-width: 680px) {
            padding: 0 0 0 30px;
            &::placeholder {
              font-size: 12px;
            }
          }
          &:focus {
            border: 1px solid var(--primary-color);
          }
          @media (max-width: 680px) {
            height: 33px;
          }
        }
        .search-img {
          position: absolute;
          top: 0;
          left: 10px;
          bottom: 0;
          height: max-content;
          margin: auto;
          img {
            @media (max-width: 680px) {
              height: 12px;
              width: 12px;
            }
          }
        }
        ::placeholder {
          @include heading-text-sm-normal;
          color: var(--text-current);
          @include font-normal;
        }
        .mobile-search-dropdown {
          width: 100%;
          z-index: 9999999;
          position: absolute;
        }
      }
    }
  }
}