.reward-details-wrap {
  .reward-details-flex-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
    .reward-details-main-wrap {
      @media (max-width: 992px) {
        width: 100%;
      }
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .product-checkbox {
        display: inline-block;
        margin-right: 15px;
        input[type="checkbox"] + label:before {
          font-size: 18px !important;
        }
      }
      .reward-details-box {
        width: 100%;
        background-color: #edf2ee;
        border-radius: 10px;
        padding: 20px;
        @media (max-width: 575px) {
          padding: 15px;
        }
        @media (min-width: 575px) {
          display: flex;
          align-items: center;
        }
        .reward-details-img {
          min-height: 100px;
          text-align: left;
          width: 22.23%;
          margin: 0 15px 0 0;
          img {
            width: 150px;
            object-fit: cover;
            max-width: 100%;
            max-height: 100px;
          }
          @media (max-width: 575px) {
            width: 100%;
            margin: 0 0 15px 0;
          }
        }
        .reward-detail-items {
          @media (min-width: 575px) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 77.77%;
          }
          .reward-details-content-wrapper {
            @media (max-width: 575px) {
              width: 100%;
            }
            color: #000;
            .reward-details-point {
              font-size: 30px;
              font-weight: 500;
              color: var(--primary-color);
            }
            .reward-details-title-btn {
              margin-bottom: 10px;
              .reward-details-title {
                margin: 10px 0 0 0;
                font-size: 15px;
                font-weight: 400;
                line-height: normal;
                @media (max-width: 1200px) {
                  font-size: calc(
                    12px + (18 - 12) * ((100vw - 280px) / (1200 - 280))
                  );
                }
                @media (max-width: 992px) {
                  margin-bottom: 10px;
                }
              }
            }
          }
          .reward-details-range-bar {
            .reward-btn {
              cursor: pointer;
              text-transform: capitalize;
              background: var(--reward-view-btn-bg-color);
              border-radius: 40px;
              color: var(--reward-view-btn-font-color);
              padding: 10px 20px;
              width: max-content;
              text-align: center;
            }
          }
        }
      }
    }
    .details-spend-reward-title {
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 10px;
      width: max-content;
      color: #fff;
      background-color: #555;
      margin-bottom: 10px;
      @media (max-width: 991px) {
        width: auto;
      }
    }
    .spend-reward-details {
      font-size: 14px;
      color: #555;
      margin-bottom: 10px;
    }
    .spend-reward-numbers {
      word-break: break-all;
    }
  }
}
