@import "styles/mixins/index";

   .main-filter-wrapper{
    @include flex-center;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
        margin-top: 15px;
    }
       .all-action-wrapper {
           position: relative;
           gap: 20px;
           // @include flex-center;
           @include breakpoint("max-md") {
               justify-content: space-between;
           }
           @media only screen and (max-width: 767px) {
               flex-wrap: wrap;
           }
           .apply-coupon {
               button {
                   background-color: var(--primary-color);
                   color: var(--bg-white);
                   border: none;
                   min-width: 135px;
                   @include font-medium;
               }
           }
           .show-items {
               margin-right: map-get($pixel, md) * 0;
               position: relative;
               @media only screen and (max-width: 450px) {
                   margin: 5px 0;
               }
               .sort-by-dropdown-align {
                   position: absolute;
                   width: 100%;
               }
               button {
                   text-transform: capitalize;
                   font-size: 15px;
                   line-height: 1.2;
                   font-weight: 500;
                   color: var(--bg-white);
                   background-color: var(--primary-color);
                   padding: 10px 20px;
                   border-radius: 50px;
                   border: none;
                   min-width: 250px;
                   @include breakpoint("max-sm") {
                       width: 200px;
                       min-width: 200px;
                   }
                   img {
                       filter: brightness(11);
                   }
                   span {
                       // display: block;
                    //    padding: 0 map-get($pixel, sm) + 4px;
                    //    display: -webkit-box;
                    //    -webkit-line-clamp: 1;
                    //    -webkit-box-orient: vertical;
                    //    overflow: hidden;
                    //    width: 100%;
                    //    text-align: left;
                   font-size: 18px;
                    display: block;
                    font-family: var(--text-font-family);
                    color: #fff;
                    line-height: 1;
                    font-weight: 500;
                    @media (max-width: 767px) {
                     font-size: 15px;
                   }
                }
               }
           }
           .show-items:last-child {
               margin-right: 0;
           }
           button {
               padding: 9px 16px;
               @include heading-text-sm-normal;
               border-radius: calc(5px - 1px);
           }
           .primary-outlined-button {
               background-color: transparent;
               border: 1px solid var(--primary-color);
               color: var(--primary-color);
               padding: 10px 20px;
               margin-right: 20px;
           }
       .clear-filter{
           cursor: pointer;
       }
   }
       .filter-search-wrapper {
           width: auto;
           position: absolute;
           margin: 0 auto;
           left: 100%;
           right: 0;
           top: 60px;
           bottom: 0;
           z-index: 99;
           background-color: #fff;
           transform: translate(0%, -50%) scale(1, 0);
           opacity: 0;
           transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
           @media (max-width: 1351px) {
               left: 0;
               top: 60px;
           }
           &.show {
               transform: translate(0%, -50%) scale(1, 1);
               opacity: 1;
           }
           //  &.hidden {
           //    transform: translate(0%, -50%) scale(1, 1);
           //    opacity: 0;
           //  }
           .filter-penal-all-block-main {
               border: 1px solid var(--primary-color);
               border-radius: 10px;
               padding: 15px;
               width: max-content;
               box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
               background-color: #fff;
               .filter-penal-all-block {
                   display: grid;
                   grid-template-columns: repeat(2, 1fr);
                   gap: 15px;
                   @media (max-width: 991px) {
                       grid-template-columns: repeat(1, 1fr);
                   }
                   @media (min-width: 991px) {
                       grid-template-columns: max(280px) minmax(320px, 1fr);
                   }
                   .filter-penal-show-block {
                       .filter-title-wrapper {
                           color: #333;
                           font-size: 18px;
                           font-weight: 700;
                           letter-spacing: .3px;
                           margin-bottom: 25px;
                           text-transform: uppercase;
                       }
                       .show-filter-items-wrapper {
                           .all-price-show-filter {
                               .show-filter-all-price {
                                   margin-bottom: 10px;
                                   &:last-of-type {
                                       margin-bottom: 20px;
                                   }
                                   label {
                                       display: flex;
                                       align-items: center;
                                       position: relative;
                                       span {
                                           padding-left: 12px;
                                       }
                                   }
                                   input[type="checkbox"]:checked+label {
                                       &:after {
                                           height: 9px;
                                           top: 6px;
                                           left: 6px;
                                       }
                                   }
                               }
                           }
                       }
                   }
                   .filter-penal-items-block {
                       .filter-title-wrapper {
                           color: #333;
                           font-size: 18px;
                           font-weight: 700;
                           letter-spacing: .3px;
                           margin-bottom: 25px;
                           text-transform: uppercase;
                           .sub-title {
                               color: #858585;
                               font-size: 14px;
                               font-weight: 400;
                               text-transform: capitalize;
                           }
                       }
                       .filter-items-wrapper {
                           display: grid;
                           grid-template-columns: repeat(2, 1fr);
                           gap: 15px;
                           @media (max-width: 550px) {
                               grid-template-columns: repeat(1, 1fr);
                           }
                           @media (min-width: 767px) {
                               grid-template-columns: max(290px) minmax(250px, 1fr);
                           }
                           .short-by-filters-item {
                               .filter-short-by-items {
                                   // border-bottom: 1px solid rgba(0, 0, 0, .07);
                                   // margin-bottom: 20px;
                                   .filter-selected-items {
                                       margin-bottom: 10px;
                                       &:last-of-type {
                                           margin-bottom: 20px;
                                       }
                                   }
                               }
                               .price-filter {
                                   .filter-price-items {
                                       margin-bottom: 10px;
                                   }
                               }
                           }
                           .all-price-filter {
                               .filter-all-price {
                                   margin-bottom: 10px;
                               }
                           }
                       }
                   }
               }
               .filter-btn {
                   display: flex;
                   gap: 10px;
                   justify-content: end;
                   margin-top: 15px;
                   flex-wrap: wrap;
                   .btn {
                       text-transform: capitalize;
                       cursor: pointer;
                       font-size: 15px;
                       line-height: 1.2;
                       font-weight: 500;
                       color: var(--bg-white);
                       background-color: var(--primary-color);
                       padding: 10px 20px;
                       border-radius: 5px;
                       border: none;
                       &.close-btn {
                           background-color: #ff0000;
                       }
                   }
               }
           }
       }
       .mobile-filter-hidden {
           transform: translateX(100%);
           transition: 0.3s ease-in-out;
       }
       .mobile-filter-show {
           transform: translateX(0%);
           transition: 0.3s ease-in-out;
       }
       .mobile-filter {
           position: fixed;
           width: 100%;
           height: 100%;
           background-color: var(--bg-white);
           overflow: auto;
           overflow-x: hidden;
           left: 0;
           top: 0;
           z-index: 999999;
           .mobile-filter-body {
               padding: map-get($pixel, sm) * 5 map-get($pixel, sm) * 4;
               .all-product-wrapper {
                   padding: 24px 0 0 0;
               }
               .sidebar-item-list {
                   padding: map-get($pixel, sm) * 5 0;
                   border-bottom: 1px solid var(--border-color-5);
                   .ift-item-with-checkbox:last-child {
                       padding: 0;
                   }
                   .ift-item-with-checkbox {
                       @include flex-center;
                       padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
                       div {
                           @include flex-center;
                           input[type="checkbox"] {
                               width: 15px;
                               height: 15px;
                               margin: 0;
                               padding: 0;
                           }
                           span {
                               @include text;
                               color: var(--gray-400);
                               display: block;
                               padding-left: map-get($pixel, sm) + 2px;
                           }
                       }
                   }
               }
               .sidebar-toggle-item {
                   padding: map-get($pixel, sm) * 5 0;
                   border-bottom: 1px solid var(--border-color-5);
                   .range-details {
                       padding: map-get($pixel, sm) * 4 0 0 0;
                       p,
                       span {
                           @include text;
                           color: var(--gray-400);
                       }
                       span {
                           @include font-semi-bold;
                       }
                   }
                   .range-style {
                       .slider {
                           -webkit-appearance: none;
                           width: 100%;
                           height: 4px;
                           background: #bfbfbf;
                           outline: none;
                           -webkit-transition: 0.2s;
                           border-radius: 999px;
                           transition: opacity 0.2s;
                       }
                       .slider:hover {
                           opacity: 1;
                       }
                       .slider::-webkit-slider-thumb {
                           -webkit-appearance: none;
                           appearance: none;
                           width: 12px;
                           height: 12px;
                           border-radius: 50%;
                           background: #04aa6d;
                           cursor: pointer;
                       }
                       .slider::-moz-range-thumb {
                           width: 12px;
                           border-radius: 50%;
                           height: 12px;
                           background: #04aa6d;
                           cursor: pointer;
                       }
                   }
               }
               .shop-by-price {
                   padding: 0 0 map-get($pixel, md) + 5px 0;
                   @include flex-between;
                   h2 {
                       @include heading-7-medium;
                       color: var(--gray-400);
                   }
                   i {
                       color: var(--light-icon);
                   }
               }
               .listing-page-sidebar {
                   .main-product {
                       .all-product-show {
                           max-height: 0px;
                           overflow: hidden;
                           @include ease-in-out;
                       }
                       .all-product-hidden {
                           max-height: calc(100vh - 0px);
                           @include ease-in-out;
                           overflow: hidden;
                       }
                   }
                   .all-product-wrapper {
                       padding: 24px 0 0 0;
                   }
                   .child-department-list {
                       padding-left: 28px;
                       .ift-item-with-checkbox {
                           @include flex-center;
                           position: relative;
                           padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
                           label {
                               @include flex-center;
                           }
                           span {
                               @include text;
                               color: var(--gray-400);
                               display: block;
                               padding-left: map-get($pixel, sm) + 2px;
                           }
                       }
                   }
                   .sidebar-title-alignment {
                       @include flex-center;
                       padding: 0 0 map-get($pixel, md) * 2 0;
                       justify-content: space-between;
                       @include pointer;
                       border-bottom: 1px solid var(--border-color-5);
                       span {
                           @include heading-7-medium;
                           color: var(--gray-400);
                           display: block;
                       }
                       i {
                           color: var(--light-icon);
                           transition: 0.3s ease-in-out;
                       }
                   }
                   .add-product {
                       @include flex-center;
                       justify-content: space-between;
                       padding: 0 0 map-get($pixel, sm) * 4 0;
                       .title-alignment {
                           @include flex-center;
                           position: relative;
                           input[type="checkbox"] {
                               width: 15px;
                               height: 15px;
                               margin: 0;
                               padding: 0;
                           }
                           label {
                               @include flex-center;
                           }
                           span {
                               @include text;
                               color: var(--gray-400);
                               display: block;
                               padding-left: map-get($pixel, sm) * 2 + 2px;
                           }
                       }
                       .add-icon {
                           @include pointer;
                           i {
                               color: var(--gray-text-color);
                           }
                       }
                   }
               }
           }
           .mobile-filter-header {
               padding: map-get($pixel, sm) * 3 map-get($pixel, sm) * 4;
               position: map-get($layout, relative);
               background-color: var(--primary-color);
               >p {
                   @include heading-4;
                   @include text-center;
                   margin: 0;
                   color: var(--bg-white);
               }
               .filter-close {
                   img {
                       @include pointer;
                       position: absolute;
                       top: 50%;
                       transform: translateY(-50%);
                   }
               }
           }
       }
    .clear-filter{
        cursor: pointer;
        color: #808080;
        font-weight: 500;
        font-style: italic;
    }
   }

   .mobile-filter{
    .main-filter-wrapper{
        padding: 16px 16px 0;
    }
   }