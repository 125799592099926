@import "styles/mixins/index";
.offer-product-list-icon {
  text-align: right;
  a {
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 6px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, .1);
    border-radius: 50%;
    display: inline-block;
    line-height: 2.8;
    width: 35px;
    height: 35px;
    text-align: center;
  }
  .new-list-input-theme2-dropdown {
    width: 223px;
    height: max-content;
    position: absolute;
    // left: 229px;
    // top: 30px;
    bottom: 0;
    position: absolute;
    top: -60%;
    right: 0;
    left: 0;
    z-index: 99999999;
    @include breakpoint("max-sm") {
      right: 0;
      left: auto;
    }
    .new-list-input-list2-style {
      filter: drop-shadow(0px 0px 20px rgba(116, 116, 116, 0.16));
      background-color: var(--bg-white);
      border: 1px solid var(--theme2-primary-color);
      border-radius: 6px;
      padding: 13px 17px;
      .save-number {
        span {
          @include text-sm-semi-bold;
          color: var(--theme2-primary-color);
          display: block;
          text-align: right;
          @include pointer;
        }
      }
      .form-control {
        margin: 0 0 9px 0;
        label {
          font-size: 15px;
          text-align: left;
          color: #000;
          line-height: 16px;
        }
        input {
          height: 24px;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
  .add-to-list-theme2-hidden {
    max-height: 0px;
    transition: 0.3s ease-in-out;
    overflow: hidden;
  }
  .add-to-list-theme2-show {
    max-height: calc(100vh - 20px);
    transition: 0.3s ease-in-out;
  }
  .add-to-list-theme2-dropdown {
    width: 221px;
    display: none;
    border-radius: 6px;
    position: absolute;
    z-index: 99999;
    right: 0px;
    left: 0;
    margin: auto;
    top: auto;
    bottom: 40px;
    &.add-to-list-theme2-show {
      display: block;
    }
    .add-list-theme2-dropdown-design {
      background-color: var(--bg-white);
      border: 1px solid var(--theme2-primary-color);
      filter: drop-shadow(0px 0px 20px rgba(116, 116, 116, 0.16));
      height: 100%;
      border-radius: 6px;
      user-select: none;
      .add-cart-btn {
        margin: 10px auto;
      }
      .add-list-text {
        @include text-sm-semi-bold;
        color: var(--secondary-color);
        margin-left: 15px;
        font-size: 16px;
      }
      .dropdown-theme2-body {
        padding: 13px 18px;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
          background-color: #f6f6f6;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(--theme2-primary-color);
        }
        .apply-text-alignment {
          display: flex;
          justify-content: flex-end;
          p {
            @include text-sm-semi-bold;
            color: var(--theme2-primary-color);
            @include pointer;
            text-align: right;
            width: fit-content;
          }
        }
        .active-text {
          p {
            color: var(--theme2-primary-color);
          }
        }
        .relative-checkbox {
          position: relative;
          @include flex-center;
          padding: 0 0 11px 0;
          label {
            @include flex-center;
            @include text-sm-normal;
            color: var(--black-5);
            span {
              padding-left: 7px;
            }
          }
        }
      }
      .new-list-theme2-title {
        background-color: #f6f6f6;
        padding: 4px 8px;
        border-radius: 6px 6px 0 0;
        // position: relative;
        p {
          @include text-sm-semi-bold;
          color: var(--secondary-color);
          margin: 0;
          text-align: right;
        }
      }
    }
  }
}