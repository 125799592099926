.detail-reward-main-wrap {
    .detail-reward-box {
        background-color: #edf2ee;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
        @media (max-width: 575px) {
            padding: 15px;
        }
        @media (min-width: 767px) {
            display: flex;
            align-items: center;
        }
        .detail-reward-img {
            background: #fff;
            border-radius: 10px;
            margin: 0;
            min-height: 180px;
            padding: 10px;
            text-align: center;
            width: 33.33%;
            margin: 0 15px 0 0;
            img {
                max-height: 200px;
            }
            @media (max-width: 767px) {
                width: 100%;
                margin: 0 0 15px 0;
            }
        }
        .detail-reward-content-wrapper {
            width: 66.66%;
            margin-left: 80px;
            @media (max-width: 1200px) {
                margin-left: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
            }
            @media (max-width: 767px) {
                width: 100%;
            }
            color: #000;
            .detail-reward-subtitle {
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 500;
                color: #000;
                line-height: 1.2;
            }
            .detail-reward-title-btn {
                margin-bottom: 10px;
                .detail-reward-title {
                    margin: 10px 0;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: normal;
                    @media (max-width: 1200px) {
                        font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                    }
                    @media (max-width: 992px) {
                        margin-bottom: 10px;
                    }
                }

            }
        }
        .detail-reward-range-bar {

            .detail-reward-point {
                font-size: 40px;
                color: var(--primary-color);
                font-weight: 600;
                margin-bottom: 10px;
            }
        }
    }
}
.reward-details-wrap{
    margin-bottom: 40px;
    @media (max-width: 575px) {
        margin-bottom: 20px;
    }
}