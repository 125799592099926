@import "styles/mixins/index";

.coupon-card-theme2 {
  border: 1px solid var(--border-color-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 270px;
  min-width: 270px;
  padding: 10px 10px 15px;
  min-height: 330px;
  overflow: hidden;
  outline: 3px solid transparent;
  outline-offset: -3px;
  transition: all 0.6s ease-in-out;
  border-radius: 10px;
  // @include pointer;
  @include ease-in-out;
  position: relative;

  @include breakpoint("max-sm") {
    margin-bottom: 20px;
  }

  //   @media (max-width: 575px) {
  //     max-width: 100%;
  //     min-width: auto;
  //     width: 100%;
  // }
  &:hover {
    border-color: var(--border-color-theme2);
    outline-color: var(--border-color-theme2);
  }

  .coupon-card-ribbons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    right: auto;
    bottom: auto;
    z-index: 11;
  }

  .coupon-used-time-ribbon {
    display: inline-block;

    .coupon-used-time {
      background-color: #ddd;
      border: none;
      font-size: 12px;
      line-height: 35px;
      margin: 0 10px;
      text-align: center;
      border-radius: 4px;
      padding: 10px;

      p {
        color: var(--bg-white);
        @include heading-text-sm-normal;

        @include breakpoint("max-md") {
          font-size: 12px;
          line-height: 16px;
        }

        span {
          @include font-medium;
          color: #000;
        }
      }
    }
  }

  .coupon-design-ribbon {
    .coupon-design {
      background-color: var(--secondary-color);
      width: -moz-fit-content;
      width: fit-content;
      margin: 2px 0 0px 2px;
      position: relative;
      color: #fff;
      border-radius: 4px;
      padding: 10px;

      p {
        color: var(--bg-white);
        @include heading-text-sm-normal;

        @include breakpoint("max-md") {
          font-size: 12px;
          line-height: 16px;
        }

        span {
          @include font-medium;
        }
      }
    }
  }

  .triangle-up-right {
    width: 0;
    height: 0;
    border-top: 50px solid var(--secondary-color);
    border-left: 50px solid transparent;
    position: absolute;
    right: 0;
    top: 0;

    .coupon-limit-align {
      position: relative;

      p {
        position: absolute;
        top: -43px;
        right: 6px;
        font-size: 14px;
        line-height: 16px;
        z-index: 10;
      }
    }
  }

  .inside-triangle {
    border-top: 46px solid #fff;
    border-left: 46px solid transparent;
    right: 1px;
    top: 1px;
  }

  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    border: 1px solid var(--border-color-4);
    min-height: unset;
  }

  &:hover {
    border: 1px solid var(--primary-color);

    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }

  .product-card-image {
    margin: 45px 0 0;
    @include flex-center;
    justify-content: map-get($layout, center);

    @include breakpoint("max-sm") {
      padding: 16px;
    }

    img {
      max-width: 170px;
      max-height: 170px;
      min-width: 170px;
      min-height: 170px;
      object-fit: contain;
      transition: all 0.6s ease-in-out;

      @include breakpoint("max-sm") {
        min-width: 92px;
        max-width: 92px;
        max-height: 116px;
        min-height: 116px;
      }
    }
  }

  .product-card-details {
    font-family: var(--text-font-family);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: map-get($pixel, md) * 1 + 4px 0 0 0;

    @include breakpoint("max-sm") {
      padding: 0;
    }

    .coupon-details {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 13px;
    }

    .regular-price {
      padding: 0 0 map-get($pixel, md) - 2px 0;

      p {
        @include text-sm;
        @include font-medium;
        color: var(--black-text-color);

        span {
          color: var(--gray-7);
          @include font-semi-bold;
        }
      }
    }

    a {
      @include heading-4;
      color: var(--black-text-color);
      display: block;
      line-height: 22px;
      @include ease-in-out;
      margin: 0 0 map-get($pixel, sm) + 3px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &:hover {
        color: var(--secondary-color);
      }
    }

    .button-group-alignment {
      @include flex-between;
      justify-content: flex-end;
      padding-top: 10px;
    }

    .final-price {
      padding: 0 0 map-get($pixel, md) - 2px 0;

      p {
        @include text-sm-normal;
        color: var(--black-text-color);
        font-size: 16px;
        @include font-medium;

        span {
          color: var(--primary-color);
          @include font-semi-bold;
        }
      }
    }

    .add-to-cart {
      .load-icon {
        text-align: center;
        display: inline-block;
        height: 38px;
        width: 38px;
        border-radius: 50%;
        line-height: 3.1;

        img {
          mix-blend-mode: multiply;
          height: 25px;
          width: 25px;
        }
      }

      button {
        border: 1px solid #bfbfbf;
        border-radius: 50%;
        background-color: var(--add-to-cart-coupon-button-bg-color);
        cursor: pointer;
        display: inline-block;
        height: 38px;
        width: 38px;
        text-align: center;
        line-height: 2.8;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        transition: all 0.5s ease-in-out;
        // z-index: 1;
        position: relative;

        &:after {
          position: absolute;
          bottom: auto;
          display: inline-block;
          padding: 3px 6px;
          color: #000;
          font-size: 12px;
          line-height: normal;
          left: auto;
          white-space: nowrap;
          content: attr(title);
          right: 0;
          top: -28px;
          margin: auto;
          width: max-content;
          background-color: #fff;
          border-radius: 4px;
          display: none;
          box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
        }

        &:hover:after {
          display: block;
        }

        svg {
          transition: all .5s ease-in-out;
        }

        &:hover:not([disabled]) {
          background-color: var(--theme2-coupon-bg-color);
          color: var(--bg-white);

          svg {
            // fill: var(--bg-white);
            transform: rotate(360deg);

            circle,
            coupon-used-time-ribbon path {
              fill: var(--bg-white);
            }
          }
        }

        span {
          padding-left: 4px;
        }

        svg {
          transition: all 0.5s ease-in-out;
          @media (max-width: 575px) {
         padding-bottom: 4px;
        }
          g {
            path {
              fill: #fff;
            }
          }
        }

        &:disabled {
          cursor: default;
        }
      }

      .cart-add {
        background-color: var(--add-to-cart-coupon-button-bg-color);

        &:hover:not([disabled]) {
          background-color: var(--bg-extra-color-theme2);
          color: var(--theme2-coupon-add-to-cart-font-color);

          svg {
            // fill: var(--bg-white);
            transform: rotate(360deg);

            g {
              path {
                fill: var(--bg-white);
              }
            }

            circle,
            path {
              fill: var(--bg-white);
            }
          }
        }
      }

      .cart-remove {
        background-color: var(--theme2-coupon-remove-cart-bg-color);
        color: var(--theme2-coupon-remove-cart-font-color);

        &:hover:not([disabled]) {
          background-color: var(--theme2-coupon-remove-cart-bg-color-hover);
          color: var(--theme2-coupon-remove-cart-font-color);

          svg {
            // fill: var(--bg-white);
            transform: rotate(360deg);

            circle,
            coupon-used-time-ribbon path {
              fill: var(--bg-black);
            }
          }
        }
      }
    }

    .button-disable {
      pointer-events: none;
      opacity: 0.5;
    }

    .button-group {
      border: 1px solid var(--border-color-5);
      @include normal-radius;
      @include flex-center;
      @include pointer;
      width: fit-content;

      .action-button,
      .counter {
        width: 24px;
        height: 23px;
        @include flex-center;
        justify-content: map-get($layout, center);
      }

      .action-button {
        color: var(--gray-text-color);
        @include ease-in-out;

        &:hover {
          background-color: var(--primary-color);
          color: var(--bg-white);
        }

        i {
          font-size: 12px;
        }
      }

      .action-button-plus {
        width: 29px;
        height: 23px;
        @include flex-center;
        color: var(--gray-text-color);
        justify-content: map-get($layout, center);
        @include ease-in-out;

        &:hover {
          background-color: var(--primary-color);
          color: var(--bg-white);
        }

        i {
          font-size: 12px;
        }
      }

      .counter {
        background-color: var(--border-color);
        @include heading-text-sm;
        color: var(--black-text-color);
      }
    }

    .price {
      @include flex-center;
      padding: 0 0 map-get($pixel, md) * 2 + 8px 0;

      h3 {
        @include text-bold;
        color: var(--primary-color);
        padding-right: 21px;
      }

      del {
        @include text-sm-semi-bold;
        font-size: 13px;
        color: var(--gray-8);
      }
    }

    .star-text-alignment {
      @include flex-center;
      padding: 0 0 map-get($pixel, sm) + 3px 0;

      .rating-text {
        span {
          @include text-xs-normal;
          color: var(--gray-7);
        }
      }

      .star-alignment {
        @include flex-center;
        padding-right: 10px;

        i:last-child {
          margin-right: 0px;
        }

        i {
          color: var(--secondary-color);
          margin-right: 3px;
          @include pointer;
          font-size: 12px;
        }
      }
    }

    h3 {
      @include text-sm;
      padding: 0 0 map-get($pixel, sm) + 2px 0;
      display: block;
      color: var(--color);
    }

    .product-card-main-detail {
      width: 70%;
    }
  }

  &:hover {
    .product-card-image {
      img {
        transform: scale(1.2);
      }
    }
  }
}