@import "styles/mixins/index";



  .catering-radio-group {
    padding: 0 0 12px 0;

    @include breakpoint("max-sm") {
      padding: 0 0 20px 0;
    }

    p {
      @include heading-4;
      margin: 0 0 map-get($pixel, md) 0;
      color: var(--black-5-light);

      span {
        color: var(--bg-danger);
      }
    }
    .catering-radio-group-item-list {
        display: grid;
        grid-template-columns: auto;
        @include breakpoint("max-sm") {
          grid-template-columns: auto;
        }
      
        .catering-item-with-radio:last-child {
          padding: 0;
        }
        .catering-item-with-radio {
          @include flex-center;
          position: relative;
          padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
          label {
            @include flex-center;
            span {
              padding-left: 7px;
            }
          }
          div {
            @include flex-center;
            input[type="radio"] {
              width: 15px;
              height: 15px;
              margin: 0;
              padding: 0;
            }
          }

          [type="radio"]:checked + label,
          [type="radio"]:not(:checked) + label
          {
              color: black;
          }
          [type=radio] {
            &:checked + label {
              &:after {
               top: 0;
               bottom: 0;
               margin: auto;
                   transform: scale(1) translateY(0%);
              }
            }
          
            &:not(:checked) + label {
              &:after {
                top: 0;
               bottom: 0;
               margin: auto;
                   transform: scale(1) translateY(0%);
              }
            }
          }
          
        }
      }

  }