@import "styles/mixins/index";

.coupon-list-grid {
 display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: map-get($pixel, sm) * 4;

  @include breakpoint("max-xl") {
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint("max-md") {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 890px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 992px) {
    gap: 0 15px;
    grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
}
  @media (max-width: 575px) {
 display: block;
}

  @include breakpoint("max-sm") {
    .coupon-list-grid-items:first-child {
      .product-card {
        padding-top: 0;
      }
    }
  }

  .coupon-list-grid-items {
    // width: 252px;
    margin-left: auto;
    margin-right: auto;
    .coupon-card-theme2{
      @media (max-width: 575px) {
        max-width: 100%;
        min-width: auto;
        width: 100%;
    }
    }
  }
}