.contact-section{
    margin-bottom: 20px;
.title-wrap{
    margin-bottom: 30px;
    @media (max-width: 1024px) {
        margin-bottom: calc(10px + (25 - 10) * ((100vw - 280px) / (1024 - 280)));
    } 
    h1{
        font-size: 70px;
        font-weight: 600;
        line-height: 1.3;
        text-transform: capitalize;
        @media (max-width: 1024px) {
            font-size: calc(20px + (60 - 20) * ((100vw - 280px) / (1024 - 280)));
        }  
    }
}
.contact-content{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px #14171C solid;
    .contact-desc{
        margin-bottom: 30px;
        @media (max-width: 1024px) {
            margin-bottom: calc(20px + (25 - 20) * ((100vw - 280px) / (1024 - 280)));
        } 
        p{
            font-size: 17px;
            line-height: 1.57;
            @media (max-width: 1024px) {
                font-size: calc(10px + (17 - 10) * ((100vw - 280px) / (1024 - 280)));
            }  
        }
    }
    .form-wrapper{
        margin: 0 0 1rem;
        input,
        .form-lable{
            display: inline-block;
        }
        .form-lable{
            @media (min-width: 991px) {

            width: 20%;
            }
            @media (max-width: 991px) {
                margin-bottom: 15px;
            }
        span{
            border: none;
            color: #ff4136;
            font-size: 0.9rem;
        }
        }
        input{
            padding: 14px 12px;
            border: 2px #dddddd solid;
            font-size: 17px;
            width: 100%;
            @media (min-width: 991px) {

                width: 80%;
                }
        }
    }
    .signup-btn-wrapper{
        .signup-btn{
            padding: 16px 35px;
            background-color: var(--primary-color);
            color: var(--bg-white);
            border-radius: calc(5px - 0px);
            border: none;
            font-size: 17px;
            font-size: 17px;
            margin: 0;
            border: none;
        }
    }
}
.message{
    font-size: 15px;
    color: #14171c;
    a{
        color:#ee0303;
    cursor: pointer;
    text-decoration: underline;
    }
}
}
.support-div{
  display: flex;
  justify-content: center;       
  text-align: center;    
  
  h1 {
    margin: 0;             
    font-size: 2rem;      
  }
}