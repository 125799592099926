@import "styles/mixins/index";

footer {
  padding: map-get($pixel, md) * 5 0 map-get($pixel, md) * 5  0;
  background-color: var(--footer-theme2-bg-color);
  @include breakpoint("max-md") {
    padding: map-get($pixel, sm) * 3 0 + 3px map-get($pixel, md) * 3 + 4px 0;
  }
  @media (max-width: 575px) {
    margin-top: 20px;
  }
  .grid {
    @media screen and (max-width: 767px) {
      .grid-items{
        .footer-logo,
        .offer,
        .payment,
        .app-title,
        .question{
          text-align: center
        }
      }
    }
    @include grid-layout;
    grid-template-columns: 230px minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 8;
    @include breakpoint("max-md") {
      grid-template-columns: 230px minmax(0, 1fr);
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
    }
    .grid-items {
      .sub-grid {
        @include grid-layout;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 map-get($pixel, md) * 8;
        @include breakpoint("max-sm") {
          grid-template-columns: repeat(2, 1fr);
          gap: 11px 36px;
        }
        .sub-grid-items {
          ul {
            li:last-child {
              padding: 0;
            }
            > li {
              font-size: 14px;
              font-weight: 300;
              line-height: 1.5;
              padding: 0 0 map-get($pixel, sm) + 3px 0;
              @include pointer;
              color: var(--footer-font-color);
              font-family: var(--text-font-family-theme2);
              @media (max-width: 767px) {
                  font-size: 14px;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .app-align {
        @include flex-between;
        padding: 0 0 map-get($pixel, sm) * 3 + 3px 0;
        @include breakpoint("max-sm") {
          @include flex-center;
          justify-content: center;
        }
        img {
          margin-right: map-get($pixel, sm) + 1px;
          @include pointer;
        }
        img:last-child {
          margin: 0;
        }
      }
      .payment {
        h4 {
          @include heading-6;
          color: var(--footer-font-color);
          margin: 0 0 map-get($pixel, md) 0;
        }
      }
      h3 {
        @include heading-6;
        color: var(--footer-title-font-color);
        font-weight: 600;
        margin: 0 0 map-get($pixel, md) 0;
        @media (max-width: 767px) {
        @include heading-6;
        }
      }
      .footer-logo {
        margin-bottom: 12px;
        img {
          @include pointer;
          // height: 55px;
          // object-fit: cover;
          //width: 177px;
          max-width: 120px;
          max-height: 70px;
        }
      }
      .offer {
        p {
          @include heading-text-sm;
          color: var(--primary-color);
        }
        a {
          @include text-sm-normal;
          @include pointer;
          font-size: 14px;
          display: block;
          color: #62b5cf;
           @media (max-width: 767px) {
                  font-size: 14px;
              }
        }
      }
      .question {
        padding: 0 0 map-get($pixel, sm) * 3 0;
        p {
          font-size: 14px;
          margin: 0 0 map-get($pixel, sm) 0;
          margin-bottom: 12px;
           @media (max-width: 767px) {
                  font-size: 14px;
              }
        }
        p,
        span,
        h2 {
          color: var(--footer-font-color);
        }
        span {
          font-size: 12px;
          color: var(--footer-title-font-color);
           @media (max-width: 767px) {
                  font-size: 14px;
              }
        }
        a {
          font-size: 12px;
          margin: 0 0 map-get($pixel, sm) + 1px 0;
          color: var(--footer-title-font-color);
          line-height: normal;
          display: block;
          @include pointer;
           @media (max-width: 767px) {
                  font-size: 14px;
              }
        }
      }
    }
  }
}
