@import "styles/mixins/index";
.product-info-theme2 {
  padding: 0 0 30px 0;
  @include breakpoint("max-sm") {
    padding: 20px 0;
  }
  @media screen and (max-width: 480px){
    padding: 20px 0;
  }
  .info-box {
    // margin: 0 70px;
    @include breakpoint("max-md") {
      margin: 0;
    }
    @include breakpoint("max-sm") {
      padding: 0;
      margin: 0;
      border: none;
    }
    .info-details {
      p {
        font-size: 14px;
        color: #808080;
        font-family: var(--text-font-family);
        @include breakpoint("max-sm") {
          @include text-sm-normal;
        }
      }
      .html-parser {
        text-align: left;
      }
      p:last-child {
        margin: 0;
      }
      .Nutrition-heading {
        margin: 0 0 map-get($pixel, md) * 2 0;
        padding-left: 15px;
      }
      .nutrition-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include breakpoint("max-md") {
          display: block;
        }
        .nutrition-image {
          width: 33%;
          overflow: hidden;
          @include breakpoint("max-sm") {
            width: 100%;
          }
          @include breakpoint("max-md") {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
        .nutrition-information {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
    .info-tab {
      padding: 0 0 map-get($pixel, md) * 2 0;
      // @include breakpoint("max-sm") {
      //   @include flex-around;
      // }
      button:last-child {
        margin: 0;
      }
      button {
        margin-right: map-get($pixel, sm) * 4;
        border: none;
        background-color: transparent;
        color: #000;
        @include text-md;
        padding: 0;
        font-size: 28px;
        font-weight: 500;
        @include breakpoint("max-sm") {
          @include text-sm-semi-bold;
        }
      }
      .active {
        border-bottom: 2px solid #000;
        padding-bottom: 8px;
        // box-shadow: (0px 0px 10px rgba(126, 126, 126, 0.16));
        color: #000;
      }
    }
  }
}