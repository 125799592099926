@import "styles/mixins/index";

.catering-product-preview-section {
  padding: map-get($pixel, md) * 7 + 7px 0 map-get($pixel, md) * 8 + 8px 0;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 4 0;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: max(620px) minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 10 + 7px;
    padding: 0 70px;

    @include breakpoint("max-md") {
      display: flex;
      gap: 40px;
      padding: 0;
      grid-template-columns: none;
      padding: 0;
    }

    @include breakpoint("max-sm") {
      display: block;
      grid-template-columns: none;
    }

    .grid-items:nth-child(2) {
      padding: map-get($pixel, md) * 6 0 0 0;
    }

    .grid-items {
      @include breakpoint("max-md") {
        width: 50%;
      }

      @include breakpoint("max-sm") {
        width: 100%;
      }

      h1 {
        @include heading-9-medium;
        margin: 0 0 map-get($pixel, md) * 2 - 1px 0;
        color: var(--black-text-color);
        max-width: 655px;

        @include breakpoint("max-md") {
          @include heading-7;
          @include font-medium;
        }
      }
      .coupon-pdp-used-time-wrapper {
        display: flex;
        // align-items: center;
        .coupon-used-pdp-item {
          margin-top: 6px;
        }
        .coupon-design {
          background-color: var(--secondary-color);
          padding: 7px 34px 7px 18px;
          margin: 0 40px map-get($pixel, md) * 3 + 5px 0;
          position: relative;
          width: fit-content;

          @include breakpoint("max-sm") {
            margin: 0 0 10px -2px;
          }

          @include breakpoint("max-md") {
            margin: 0 50px map-get($pixel, md) * 2 0;
          }

          p {
            color: var(--bg-white);
            @include heading-4;

            @include breakpoint("max-md") {
              font-size: 12px;
              line-height: 16px;
            }

            span {
              @include font-medium;
            }
          }

          &::before {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            top: 0;
            border-width: 21px 12px 19px 0;
            position: absolute;
            content: "";
            right: 0;
            border-color: transparent var(--bg-white) transparent transparent;

            @include breakpoint("max-md") {
              border-width: 15px 12px 19px 0;
            }
          }
        }
      }

      .final-price {
        h2 {
          color: var(--black-5-light);
          font-weight: 500;
        }
      }
      .notice {
        background-color: #fef7e0;
        border: 1px solid #f5e1a1;
        padding: 10px 10px 8px;
        border-radius: 5px;
        margin-bottom: 20px;
        display: flex;
        .icon {
          display: inline-block;
          vertical-align: sub;
          line-height: 1.1;
          svg {
            vertical-align: middle;
          }
        }
        span {
          margin-left: 5px;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          @media (max-width: 1200px) {
            font-size: calc(13px + (15 - 13) * ((100vw - 280px) / (1200 - 280)));
          }
        }
      }
      .department-code {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 + 0px 0;

        div:first-child {
          padding-right: map-get($pixel, md) * 4;
        }

        div {
          p {
            @include text-sm-normal;
            color: var(--black-5-light);

            span {
              color: var(--primary-color);
            }
          }
        }
      }
      .restricted {
        p {
          color: var(--bg-danger);
        }
      }
      .substitution {
        padding: 0 0 12px 0;

        @include breakpoint("max-sm") {
          padding: 0 0 20px 0;
        }

        p {
          @include heading-4;
          margin: 0 0 map-get($pixel, md) 0;
          color: var(--black-5-light);

          span {
            color: var(--bg-danger);
          }
        }

        .substitution-border {
          border: 1px solid var(--light-border-color) !important;
        }

        .select-substitition-dropdown {
          position: relative;
          width: fit-content;

          button {
            @include heading-text-sm;
            padding: 12px 17px;
            border: 1px solid var(--border-color-2);
            color: var(--black-5-light);
            border-radius: 5px;
            @include flex-center;
            justify-content: map-get($layout, space-between);
            background-color: transparent;
            @include font-normal;
            width: 100%;
            min-width: 155px;
            max-width: 288px;
            position: relative;

            span {
              padding-right: map-get($pixel, md) - 3px;
            }
          }

          .substitution-dropdown {
            position: absolute;
            width: 100%;

            .list-menu {
              border: none;
            }
          }

          .show-items {
            margin-right: map-get($pixel, md) * 2;
            position: relative;
          
        

             .dropdown-show {
              .list-menu{
                max-height: 250px;
                overflow-y: auto
              }
             }
             .dropdown-hidden {
               max-height: 0px;
               overflow: hidden;
               transition: none;
               -webkit-transition: none;
               -moz-transition: none;
               -ms-transition: none;
               -o-transition: none;
            }
          }
        }
      }

      .add-product-option {
        @include flex-center;
        padding: 12px 0 0 0;

     

        @include breakpoint("max-sm") {
          display: flex;
          flex-wrap: wrap;
        }
        .button-disable {
          pointer-events: none;
          opacity: 0.5;
        }

        .link-button {
          margin-left: map-get($pixel, md);

          @include breakpoint("max-md") {
            padding: map-get($pixel, md) * 2 0 0 0;
            margin-left: 0;
          }

          @include breakpoint("max-sm") {
            padding: map-get($pixel, md) * 2 - map-get($pixel, md) * 2 0 0 0;
            margin-left: 0;
          }

          button {
            width: 137px;
            height: 50px;
            @include flex-center;
            justify-content: map-get($layout, center);
            border-radius: 5px;
            background-color: transparent;
            border: 1px solid #ccc;

            i {
              color: #ccc;
            }
          }

          .fa-solid {
            color: red;
          }
        }

        .add-to-cart {
       

          @include breakpoint("max-sm") {
            padding-bottom: map-get($pixel, md);
          }

          button {
            background-color: var(--primary-color);
            height: 50px;
            padding: 10px 25px;
            border-radius: 5px;
            @include flex-center;
            justify-content: map-get($layout, center);
            border: none;
            @include text-sm-normal;
            @include font-semi-bold;
            color: var(--bg-white);
            min-width: 155px;

            &:disabled {
              cursor: default;
              opacity: 0.5;
            }

            span {
              padding-left: map-get($pixel, md);
            }
          }
        }
        .new-list-input-dropdown {
          @include breakpoint("max-sm") {
            right: -110px;
          }
        }
        .couter-button {
          padding-right: map-get($pixel, md);
          @include breakpoint("max-sm") {
            padding-bottom: map-get($pixel, md);
          }
          button {
            min-width: 90px;
            height: 50px;
            @include heading-text-md-medium;
            color: var(--primary-color);
            position: relative;
            border-radius: 5px;
            cursor: text;
            background-color: transparent;
            text-align: left;
            border: 2px solid var(--primary-color);

            &:disabled {
              cursor: default;
              pointer-events: none;
              opacity: 0.6;
            }

            span {
              padding-left: 30px;
              display: block;
            }

            .arrow-right-side {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 14px;

              i {
                font-size: 12px;
                @include pointer;
              }
            }
          }
          &:disabled {
            pointer-events: none;
            opacity: 0.6;
            cursor: default;
          }
        }
      }

      .other-price-details {
        p {
          padding-bottom: 5px;
        }

        p:first-child & p:last-child {
          padding: 0 0 map-get($pixel, md) * 2 0;
        }
      }

      .final-price {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-md") {
          display: block;
        }

        .price {
          padding-right: map-get($pixel, md) * 3 + 4px;

          h2 {
            @include text-5xl;
            color: var(--primary-color);
            margin: 0;
          }
        }

        .discount-price {
          p {
            @include text-sm-semi-bold;
            color: #fcbb0a;
            margin: 0;
          }

          span {
            @include text-4xl;
            display: block;
            color: var(--gray-8);
          }
        }
      }

      .rating-alignment {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 + 3px 0;

        .review-text {
          span {
            @include text;
            color: var(--gray-7);
          }
        }

        .star-alignment {
          @include flex-center;
          padding-right: map-get($pixel, sm) * 3 - 1px;

          i:last-child {
            color: #c2c2c2;
            margin: 0;
          }

          i {
            color: #f9c945;
            @include pointer;
            margin-right: map-get($pixel, sm);
          }
        }
      }
    }
  }
}