@import "styles/mixins/index";
.order-details-theme2 {
  @include breakpoint("max-sm") {
    margin-top: 20px;
  }
  .order-details-box-wrapper {
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    .oreder-details-title-list {
      @include flex-between;
      padding: 20px;
      border-bottom: 1px #e6e6e6 solid;
      @include breakpoint("max-sm") {
        padding: 15px;
      }
      @media (max-width: 767px) {
        flex-wrap: wrap;
        gap: 10px;
      }
      .order-details-page-title {
        @include flex-between;
        @media (max-width: 650px) {
          flex-wrap: wrap;
          gap: 5px;
        }
        h1 {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          color: var(--invert-secondary-color);
          @include breakpoint("max-sm") {
            font-size: 14px;
          }
        }
        span {
          position: relative;
          color: #4d4d4d;
          font-size: 13px;
          font-weight: 500;
          margin: 0 0 0 15px;
          padding: 0 0 0 20px;
          position: relative;
          @media (max-width: 575px) {
            margin: 0;
            padding: 0;
          }
          &::before {
            background-color: #4d4d4d;
            border-radius: 50px;
            content: "";
            height: 5px;
            left: 0;
            position: absolute;
            top: 0;
            width: 5px;
            bottom: 0;
            margin: auto;
            @media (max-width: 575px) {
              display: none;
            }
          }
        }
      }
      .oreder-details-list {
        color: #20b526;
        font-weight: 600;
      }
    }
    .order-card {
      .order-list-step-wrap {
        padding: 20px;
      }
      .order-item-list-wrapper {
        margin: 0 0 30px;
        @media (min-width: 767px) {
          display: flex;
        }
        .order-item-list {
          border: 1px solid #e6e6e6;
          border-radius: 10px;
          display: flex;
          margin: 0 0 15px 0;
          @media (max-width: 992px) {
            display: block;
          }
          @media (min-width: 767px) {
            margin: 0;
            width: 65%;
          }
          .order-address-items {
            .order-address-book {
              .address-title {
                border-bottom: 1px solid #ddd;
                padding: 15px;
                color: #999;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: .4px;
              }
              .address-description {
                padding: 15px;
                .info {
                  color: #8b8b8b;
                  font-size: 14px;
                  line-height: 24px;
                  margin: 0 0 20px;
                }
                .user-name {
                  color: #000;
                  display: block;
                  font-weight: 500;
                  margin: 0 0 20px;
                  font-size: 14px;
                  text-transform: capitalize;
                }
                .user-email-box {
                  span {
                    color: #000;
                    display: block;
                    font-weight: 500;
                  }
                }
                .user-address-box {
                  span {
                    color: #000;
                    display: block;
                    font-weight: 500;
                  }
                }
                .user-phone-box {
                  span {
                    color: #000;
                    display: block;
                    font-weight: 500;
                  }
                }
                .user-method-box {
                  span {
                    color: #000;
                    display: block;
                    font-weight: 500;
                  }
                }
                .user-time-box {
                  span {
                    color: #000;
                    display: block;
                    font-weight: 500;
                  }
                }
                .user-picup-date {
                  span {
                    color: #000;
                    display: block;
                    font-weight: 500;
                  }
                }
                .user-status-box {
                  span {
                    color: #000;
                    display: block;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
        .order-steps {
          border: 1px solid #e6e6e6;
          border-radius: 10px;
          @media (min-width: 767px) {
            margin: 0 0 0 15px;
            width: 35%;
          }
          .payment-title {
            border-bottom: 1px solid #ddd;
            display: flex;
            padding: 15px;
            font-size: 13px;
            justify-content: space-between;
            .order-id {
              span {
                color: #1a1a1a;
                text-transform: capitalize;
              }
            }
            .method-title {
              margin: 0;
            }
          }
          .order-pyment-steps {
            padding: 15px;
            p {
              border-bottom: 1px solid #ddd;
              color: #565656;
              font-size: 14px;
              font-weight: 400;
              margin: 0 0 15px;
              padding: 0 0 15px;
              span {
                color: #000;
                float: right;
                font-weight: 500;
              }
            }
            h6 {
              color: #565656;
              font-size: 15px;
              font-weight: 500;
              margin: 0;
              span {
                color: #2c742f;
                float: right;
                font-weight: 600;
              }
            }
          }
        }
      }
      .order-steps {
        margin: 0 auto 10px;
        @media (min-width: 767px) {
          margin: 0 auto 20px;
          width: 90%;
        }
        ul {
          display: flex;
          position: relative;
          @media (max-width: 767px) {
            flex-wrap: wrap;
          }
          li {
            position: relative;
            text-align: center;
            width: 100%;
            @media (max-width: 767px) {
              width: 50%;
              margin-bottom: 10px;
            }
            .circle {
              background-color: #fff;
              border: 1px dashed var(--theme2-primary-color);
              border-radius: 50px;
              color: var(--theme2-primary-color);
              display: block;
              font-size: 13px;
              height: 40px;
              width: 40px;
              line-height: 40px;
              margin: auto;
              position: relative;
              text-align: center;
              z-index: 9;
              @media (max-width: 767px) {
                height: 25px;
                width: 25px;
                line-height: 25px;
              }
            }
            &.complete {
              .circle {
                background-color: var(--theme2-primary-color);
                color: #fff;
              }
              &::before {
                background-color: var(--theme2-primary-color);
              }
            }
            .text {
              color: #505050;
              display: inline-block;
              font-size: 14px;
              font-weight: 500;
              margin: 5px 0 0;
              @media (max-width: 767px) {
                font-size: 12px;
              }
            }
            &::before {
              background-color: #f1eded;
              content: "";
              height: 7px;
              left: 50%;
              position: absolute;
              top: 17px;
              width: 100%;
              @media (max-width: 767px) {
                display: none;
              }
            }
            &:last-child {
              &::before {
                content: none;
              }
            }
          }
        }
      }
      .product-table {
        thead{
          tr{
            position: unset;
            display: table-row;
          }
        }
        table {
          width: 100%;
          border-spacing: 0;
          th {
            background-color: #f2f2f2;
            color: #606060;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: .5px;
            padding: 10px 15px;
            text-transform: uppercase;
            @media (max-width: 600px) {
              display: table-cell;
              width: 100%;
              font-size: 8px;
            }
          }
          .product-body{
            @media (max-width: 600px) {
              display: table-row-group;
            }
            tr{
              @media (max-width: 600px) {
                display: table-row;
              }
              td {
                border-bottom: 1px solid #ddd;
                color: #000;
                font-weight: 500;
                padding: 10px 20px;
                text-align: center;
                display: table-cell;
                @media (max-width: 767px) {
                  padding: 10px;
                }
                @media (max-width: 600px) {
                  font-size: 10px;
                }
                .product-image-wrap {
                  display: flex;
                  align-items: center;
                  .product-image {
                    width: 50px;
                    display: block;
                    img {
                      max-width: 100%;
                    }
                  }
                  .product-title {
                    text-align: left;
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0 0 0 15px;
                    @media (max-width: 600px) {
                        font-size: calc(9px + (12 - 9) * ((100vw - 280px) / (600 - 280)));
                    }
                  }
                }
              }
              &:last-child{
                td{
                  border: none;
                }
              }
            }
          }
        }
        // @media (max-width: 600px) {
        //   overflow-x: auto;
        //   &::-webkit-scrollbar {
        //     background: #999;
        //     height: 10px;
        //   }
        //   &::-webkit-scrollbar-thumb {
        //     background-color: var(--primary-color);
        //   }
        // }
      }
    }
  }
  .invoice-footer-button {
    @include flex-center;
    padding: 30px 0 0 0;
    justify-content: center;
    @include breakpoint("max-sm") {
      padding: 20px 0 0 0;
    }
    button:last-child {
      margin: 0;
      background-color: var(--primary-color);
    }
    button:first-child {
      background-color: var(--secondary-color);
    }
    button {
      padding: 12px;
      color: var(--text-white);
      border-radius: 4px;
      margin-right: 15px;
      border: none;
      @include heading-text-sm;
      span {
        padding-left: 7px;
      }
    }
  }
}