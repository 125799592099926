@import "styles/mixins/index";
.details-reward-main-wrap {
    margin-top: 20px;
    .details-reward-items{
        .reward-items-wrap{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
              @media screen and (max-width: 992px) {
                grid-template-columns: repeat(1, 1fr);
              }
            .details-reward-box {
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0px 5px 10px 10px rgba(23, 83, 116, 0.051);
                padding: 10px;
                @media (max-width: 992px) {
                    width: 100%;
                }
                @media (min-width: 575px) {
                    display: flex;
                    align-items: center;
                }
                .details-reward-img {
                    line-height: 1.2;
                    min-height: 70px;
                    text-align: center;
                    margin: 0 15px 0 0;
                    img {
                        max-height: 70px;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        text-align: left;
                        margin: 0 0 15px 0;
                    }
                }
                .details-reward-content-wrapper {
                    @media (max-width: 575px) {
                        width: 100%;
                    }
                    color: #000;
                    .details-reward-title-btn {
                        margin-bottom: 5px;
                        .details-reward-title {
                            margin: 0 0 5px 0;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: normal;
                            @media (max-width: 1200px) {
                                font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                            }
                            @media (max-width: 992px) {
                                margin-bottom: 10px;
                            }
                        }
                        .details-reward-point-btn {
                            cursor: pointer;
                            text-transform: capitalize;
                            color: #000;
                        }
                    }
                }
                .details-reward-range-bar {
                    .details-reward-point {
                        font-size: 16px;
                        color: var(--primary-color);
                        font-weight: 600;
                    }
                }
            }
        }
    }
}