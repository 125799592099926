@import "styles/mixins/index";

.wishlist-section-theme2 {
    .wishlist-page-title {
        margin: 0 0 27px 0;
        @include flex-between;

        @include breakpoint("max-sm") {
            padding: 27px 0;
        }


        h1 {
            @include heading-2;
            margin: 0;
            color: var(--invert-secondary-color);

            @include breakpoint("max-sm") {
                @include heading-3xl;
            }
        }

        button {
            padding: 8px 20px;
            background-color: var(--primary-color);
            color: var(--bg-white);
            @include heading-text-sm;
            border: none;
            border-radius: 4px;
        }
    }

    .wishlist-table-wrapper {
        position: relative;
        margin-bottom: 20px;
        border-radius: 8px;
        border: 1px solid var(--border-color-8);
        overflow: hidden;

        table {
            border-collapse: collapse;
        }

        tr:first-child {
            background-color: var(--primary-light-color);
            border-radius: 4px 4px;
        }

        th {
            padding: 15px 20px;
            color: var(--invert-primary-color);
            @include heading-text-sm;
        }

        td {
            padding: 13px 16px;
            @include text-xs-normal;
            color: #373737;

            button {
                padding: 6px 22px;
                background-color: var(--secondary-color);
                color: var(--bg-white);
                @include heading-text-sm-normal;
                @include font-medium;
                height: 40px;
                border: none;
                border-radius: 4px;
            }
        }


        .scrollable {
            table {
                width: 100%;
            }
        }
    }

    @media (max-width: 1100px) {
        .wishlist-table-wrapper {
            border-right: 1px solid #ccc;

            .scrollable {
                overflow: scroll;
                overflow-y: hidden;

                table {

                    th,
                    td {
                        white-space: nowrap;
                    }

                }
            }

            .pinned {

                table {
                    border-right: none;
                    border-left: none;
                    width: 100%;
                }
            }
        }
    }
}