//@import url(https://fonts.googleapis.com/css?family=Work+Sans|Open+Sans:wght@400;500;600&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

:root {
  // Font family
  //--heading-font-family: "Work Sans";
  //--text-font-family: "Open Sans";
  //--default-font-family: sans-serif;
  // font theme 2
  --text-font-family: "Poppins", sans-serif;
  --heading-font-family: "Poppins", sans-serif;
  --default-font-family: "Poppins";

  // main colors
  --primary-color: #13c487;
  --secondary-color: #fea133;
  --primary-light-color: #e5fef2;
  --invert-primary-color: #010101;
  --invert-secondary-color: #253d4d;

  //slider text colors
  --slider-text-h1-color: #000;
  --slider-text-h2-color: #ffffff;
  --slider-text-p-color: #000;

  // child color
  --gray-text-color: #707070;
  --black-3: #343434;
  --black-text-color: #000;
  --black-5-light: #505050;
  --black-6: #060606;
  --gray-5: #535353;
  --gray-2: #e1d8d8;
  --gray-7: #7a7a7a;
  --black-200: #2b2b2b;
  --gray-6: #6a6a6a;
  --gray-8: #858585;
  --gray-9: #979797;
  --black-1: #171717;
  --black-4: #313030;
  --gray-4: #444444;
  --dark-gray: #4e4e4e;
  --dark-gray-300: #363636;
  --text-current: #6e6e6e;
  --text-white: #fff;
  --gray-3: #333;
  --gray-500: #5d5d5d;
  --black-light-300: #202020;
  --gray-400: #484848;
  --bg-light-600: #f8f8f8;

  // border-color
  --light-border-color: #d6d6d6;
  --gray-border-color: #eeeeee;
  --border-color: #e9e9e9;
  --border-color-2: #a5a5a5;
  --border-color-3: #f2f2f2;
  --border-color-4: #dbdbdb;
  --border-color-5: #c5c5c5;
  --border-color-6: #b5b5b5;
  --border-color-7: #d1d1d1;
  --border-color-8: #e6e6e6;
  --border-color-9: #ececec;
  --border-color-10: #a7a7a7;
  --border-color-11: #8b8b8b;
  --border-light: #c9c9c9;
  // bg-color
  --bg-light: #f9f9f9;
  --bg-primary-light: #defff0;
  --bg-dark-black: #171717;
  --bg-light-300: #f7f7f7;
  --bg-danger: #da2327;
  --bg-white: #fff;
  --bg-black: #030303;
  --bg-light-400: #ebebeb;
  --bg-light-200: #e4e4e4;

  // icon color
  --black-icon: var(--black-text-color);
  --light-icon: #3b3b3b;
  --cookie-background-color: #fea133;
  --cookie-btn-background-color: #13c487;

  // add to cart button
  --add-to-card-bg-color: #13c487;
  --add-to-card-font-color: #ffffff;

  // cart page table title
  --cart-page-header-title-bg-color: var(--bg-primary-light);
  --cart-page-header-title-font-color: var(--black-1);

  // checkout page message
  --checkout-page-message-bg-color: var(--bg-primary-light);
  --checkout-page-message-font-color: var(--primary-color);

  // checkout page amount information
  --checkout-page-amount-info-bg-color: var(--bg-primary-light);
  --checkout-page-amount-info-font-color: var(--black-1);

  // coupon tag lable
  --coupon-bg-color: var(--secondary-color);
  --coupon-text-color: var(--bg-white);

  // add-outofstock color to cart button
  --add-to-cart-outofstock-bg-color: #ECECEC;
  --add-to-cart-outofstock-font-color: #a4a4a4;

  // product sale lable color
  --add-to-cart-sale-bg-color: #DC291E;
  --add-to-cart-sale-font-color: #ffffff;

  // newsletter button
  --newsletter-btn-bg-color: #d42027;
  --newsletter-btn-font-color: #ffffff;

  --breadcrumb-bg-color: #000;
  --breadcrumb-text-color: #00b207;
  --breadcrumb-sub-text-color: #fff;

  //bg color theme 2
  --bg-color-theme2: #13c487;
  --add-to-cart-coupon-button-bg-color: #13c487;
  --bg-extra-color-theme2: #00b207;

  // product colors theme2 
  --product-tag-bg-color: #fcac1f;
  --border-color-theme2: #00b207;

  //reward page btn
  --reward-redeem-btn-bg-color: #00b207;
  --reward-redeem-btn-font-color: #fff;
  --reward-view-btn-bg-color: #00b207;
  --reward-view-btn-font-color: #fff;

  //coupon add btn hover color 
  --theme2-coupon-remove-cart-bg-color: #fff;
  --theme2-coupon-remove-cart-font-color: #000;

  //primary colors
  --theme2-secondary-color: #fcac1f;
  --theme2-primary-color: #00b207;
}

.whitesfoodliner-theme {
  --color: #7a7a7a;
  --primary-color: #00b207; //change
  --secondary-color: #fcac1f; //change
  --primary-light-color: #93d496b3; // change
  --invert-primary-color: #010101;
  --invert-secondary-color: #253d4d;
  // --heading-font-family: "helvetica";
  // --text-font-family: "Open Sans";
  --heading-font-family: "Poppins", sans-serif;
  --text-font-family: "Poppins", sans-serif;
  --text-font-family-theme2: "Poppins", sans-serif;
  --black-3: #343434;
  --cookie-background-color: #fea133;
  --cookie-btn-background-color: #13c487;

  --slider-text-h1-color: #253d4d; //invert -secondary
  --slider-text-h2-color: #fea133; // secondary

  // add to cart button
  --add-to-card-bg-color: #e5fef2;
  --add-to-card-font-color: #13c487;

  // cart page table title
  --cart-page-header-title-bg-color: #93d496b3; //change
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #93d496b3; //change
  --checkout-page-message-font-color: #171717; //change

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #93d496b3; //change
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #fea133;
  --coupon-text-color: #ffffff;

  // newsletter button
  --newsletter-btn-bg-color: #fea133;
  --newsletter-btn-font-color: #ffffff;

  // navigation bar theme 2
  --navigation-bar-theme2: #FCAC1F;
  --navigation-bar-theme2-text-color: #000000;

  // shop-by-department
  --shop-by-department-theme2: #333333;
  --shop-by-department-img-theme2: #00b207;

  // text color for theme2
  --fill-svg-color-theme2: #FCAC1F;

  //bg color theme 2
  --bg-color-theme2: #00b207;

  //footer bg color theme2
  --footer-theme2-bg-color: #000000;
  --footer-font-color: #999999;
  --footer-title-font-color: #ffffff;

  // product colors theme2 
  --product-tag-bg-color: #fcac1f;

  // product theme2 text color 
  --product--theme2-text-color: #fcac1f;

  //product theme2 btn color 
  --product-btn-theme2-color: #f2f2f2;

  //border color theme2
  --border-color-theme2: #00b207;

  //product theme2 bg color
  --bg-color-white-theme2: #fff;
  --text-color-theme2: #00b207;

  //theme2 secondary color
  --theme2-secondary-color: #fcac1f;
  --theme2-primary-color: #00b207;

  --bg-extra-color-theme2: #00b207;
  //coupon btn bg color
  --theme2-coupon-add-to-cart-font-color: #171717;
  --theme2-coupon-add-to-cart-font-color-hover: #171717;
  --theme2-coupon-add-to-cart-bg-color: #ffffff;
  --theme2-coupon-add-to-cart-bg-color-hover: #00b207;

  --theme2-coupon-remove-cart-font-color: #ffffff;
  --theme2-coupon-remove-cart-font-color-hover: #ffffff;
  --theme2-coupon-remove-cart-bg-color: #a80727;
  --theme2-coupon-remove-cart-bg-color-hover: #a80727;
  --theme2-coupon-bg-color: #a80727;

  // font theme 2
  --text-font-family-theme2: "Poppins", sans-serif;

  //compaign product colors
  --theme2-campaign-secondary-color: #000;
  --theme2-campaign-primary-color: #fff;

  --breadcrumb-bg-color: #000;
  --breadcrumb-text-color: #00b207;
  --breadcrumb-sub-text-color: #fff;

  --add-to-cart-coupon-button-bg-color: #00b207;
}

.tealsmarket-theme {
  --heading-font-family: "Poppins", sans-serif;
  --text-font-family: "Poppins", sans-serif;
  --text-font-family-theme2: "Poppins", sans-serif;
  --invert-primary-color: #585858;
  --primary-color: #00b207; //change
  --secondary-color: #fcac1f; //change
  // --heading-font-family: "helvetica";
  // --text-font-family: "Open Sans";

  // navigation bar theme 2
  --navigation-bar-theme2: #fff;
  --navigation-bar-theme2-text-color: #000000;

  // shop-by-department
  --shop-by-department-theme2: #333333;
  --shop-by-department-img-theme2: #585858;

  // text color for theme2
  --fill-svg-color-theme2: #FCAC1F;

  //bg color theme 2
  --bg-color-theme2: #00b207;

  //bg-color-grey
  --bg-extra-color-theme2: #585858;

  //popup header bg color
  --primary-light-color: #dfdfdf;

  //footer bg color theme2
  --footer-theme2-bg-color: #000000;
  --footer-font-color: #999999;
  --footer-title-font-color: #ffffff;

  // product colors theme2 
  --product-tag-bg-color: #fcac1f;

  // product theme2 text color 
  --product--theme2-text-color: #fcac1f;

  //product theme2 btn color 
  --product-btn-theme2-color: #f2f2f2;

  //border color theme2
  --border-color-theme2: #00b207;

  //product theme2 bg color
  --bg-color-white-theme2: #fff;
  --text-color-theme2: #00b207;

  //theme2 secondary color
  --theme2-secondary-color: #fcac1f;
  --theme2-primary-color: #00b207;

  //coupon btn bg color
  --theme2-coupon-add-to-cart-font-color: #171717;
  --theme2-coupon-add-to-cart-font-color-hover: #171717;
  --theme2-coupon-add-to-cart-bg-color: #ffffff;
  --theme2-coupon-add-to-cart-bg-color-hover: #00b207;

  --theme2-coupon-remove-cart-font-color: #ffffff;
  --theme2-coupon-remove-cart-font-color-hover: #ffffff;
  --theme2-coupon-remove-cart-bg-color: #a80727;
  --theme2-coupon-remove-cart-bg-color-hover: #a80727;
  --theme2-coupon-bg-color: #a80727;

  // font theme 2
  --text-font-family-theme2: "Poppins", sans-serif;

  //compaign product colors
  --theme2-campaign-secondary-color: #000;
  --theme2-campaign-primary-color: #fff;

  --breadcrumb-bg-color: #a29f80;
  --breadcrumb-text-color: #000;
  --breadcrumb-sub-text-color: #000;

  --add-to-cart-coupon-button-bg-color: #00b207;
}

.raysapplemarket-theme {
  --color: #7a7a7a;
  --primary-color: #227f19;
  --secondary-color: #227f19;
  --primary-light-color: #8fd788;
  --invert-primary-color: #227f19;
  --invert-secondary-color: #253d4d;
  --heading-font-family: "helvetica";
  --text-font-family: "Open Sans";
  --black-3: #343434;
  --cookie-background-color: #227f19;
  --cookie-btn-background-color: #8fd788;
  --bg-dark-black: #227f19;

  --slider-text-h1-color: #253d4d; //invert -secondary
  --slider-text-h2-color: #e2efe1; // secondary

  // add to cart button
  --add-to-card-bg-color: #8fd788;
  --add-to-card-font-color: #227f19;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #227f19;
  --coupon-text-color: #ffffff;

  // newsletter button
  --newsletter-btn-bg-color: #171717;
  --newsletter-btn-font-color: #ffffff;

}

.aogdemo-theme {
  --color: #7a7a7a;
  --primary-color: #a80727;
  --secondary-color: #a80727;
  --primary-light-color: #df8d92;
  --invert-primary-color: #a80727;
  --invert-secondary-color: #253d4d;
  --heading-font-family: "helvetica";
  --text-font-family: "Open Sans";
  --black-3: #343434;
  --cookie-background-color: #a80727;
  --cookie-btn-background-color: #df8d92;
  --bg-dark-black: #a80727;

  --slider-text-h1-color: #253d4d; //invert -secondary
  --slider-text-h2-color: #e2efe1; // secondary

  // add to cart button
  --add-to-card-bg-color: #a80727;
  --add-to-card-font-color: #ffffff;

  // cart page table title
  --cart-page-header-title-bg-color: #a80727;
  --cart-page-header-title-font-color: #ffffff;

  // checkout page message
  --checkout-page-message-bg-color: #a80727;
  --checkout-page-message-font-color: #ffffff;

  // coupon tag lable
  --coupon-bg-color: #e1ad01;
  --coupon-text-color: #ffffff;

  // add-outofstock color to cart button
  --add-to-cart-outofstock-bg-color: #ECECEC;
  --add-to-cart-outofstock-font-color: #a4a4a4;

  // product sale lable color
  --add-to-cart-sale-bg-color: #DC291E;
  --add-to-cart-sale-font-color: #ffffff;

  // newsletter button
  --newsletter-btn-bg-color: #4b4b4b;
  --newsletter-btn-font-color: #ffffff;
}

.mosersfoods-theme {
  --color: #7a7a7a;
  --primary-color: #227f19;
  --secondary-color: #227f19;
  --primary-light-color: #8fd788;
  --invert-primary-color: #227f19;
  --invert-secondary-color: #253d4d;
  --heading-font-family: "helvetica";
  --text-font-family: "Open Sans";
  --black-3: #343434;
  --cookie-background-color: #227f19;
  --cookie-btn-background-color: #8fd788;
  --bg-dark-black: #227f19;

  --slider-text-h1-color: #253d4d; //invert -secondary
  --slider-text-h2-color: #e2efe1; // secondary

  // add to cart button
  --add-to-card-bg-color: #8fd788;
  --add-to-card-font-color: #227f19;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #227f19;
  --coupon-text-color: #ffffff;

  // newsletter button
  --newsletter-btn-bg-color: #171717;
  --newsletter-btn-font-color: #ffffff;
}

.freshfoods-theme {
  --color: #7a7a7a;
  --primary-color: #1c1e1d;
  --secondary-color: #2f9d4c;
  --primary-light-color: #8fd788;
  --invert-primary-color: #2f9d4c;
  --invert-secondary-color: #253d4d;
  --heading-font-family: "helvetica";
  --text-font-family: "Open Sans";
  --black-3: #343434;
  --cookie-background-color: #227f19;
  --cookie-btn-background-color: #8fd788;
  --bg-dark-black: #2f9d4c;

  --slider-text-h1-color: #8fd788; //invert -secondary
  --slider-text-h2-color: #1c1e1d; // secondary

  // add to cart button
  --add-to-card-bg-color: #8fd788;
  --add-to-card-font-color: #1c1e1d;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #fea133;
  --coupon-text-color: #ffffff;

  // newsletter button
  --newsletter-btn-bg-color: #171717;
  --newsletter-btn-font-color: #ffffff;
}

.millersmarkets-theme {
  --color: #313131;
  --primary-color: #313131;
  --secondary-color: #bf0012;
  // --primary-light-color: #ff4e48;
  --primary-light-color: #2D65DC;
  --invert-primary-color: #354650;
  --invert-secondary-color: #393c41;
  --heading-font-family: "helvetica";
  --text-font-family: "Work Sans";
  --black-3: #343540;
  --cookie-background-color: #ff4e48;
  --cookie-btn-background-color: #bf0012;

  --slider-text-h1-color: #393c41; //invert -secondary
  --slider-text-h2-color: #bf0012; // secondary

  // add to cart button
  --add-to-card-bg-color: #2D65DC;
  --add-to-card-font-color: #354650;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #bf0012;
  --coupon-text-color: #ffffff;

  // newsletter button
  --newsletter-btn-bg-color: #171717;
  --newsletter-btn-font-color: #ffffff;
}

.market33-theme {
  --color: #313131;
  --primary-color: #313131;
  --secondary-color: #585858;
  // --primary-light-color: #ff4e48;
  --primary-light-color: #A29F80;
  --invert-primary-color: #585858;
  --invert-secondary-color: #585858;
  // --heading-font-family: "Minion Pro";
  // --text-font-family: "Crimson Text", serif;
  --heading-font-family: "Poppins", sans-serif;
  --text-font-family: "Poppins", sans-serif;
  --black-3: #585858;
  --cookie-background-color: #996C6E;
  --cookie-btn-background-color: #A19C8F;

  --slider-text-h1-color: #585858; //invert -secondary
  --slider-text-h2-color: #585858; // secondary

  // add to cart button
  --add-to-card-bg-color: #A29F80;
  --add-to-card-font-color: #313131;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #585858;
  --coupon-text-color: #ffffff;

  // newsletter button
  --newsletter-btn-bg-color: #585858;
  --newsletter-btn-font-color: #171717;

  //bg color theme 2
  --bg-color-theme2: #585858;

  //bg-color-grey
  --bg-extra-color-theme2: #585858;

  // product colors theme2 
  --product-tag-bg-color: #585858;

  // product theme2 text color 
  --product--theme2-text-color: #585858;

  // navigation bar theme 2
  --navigation-bar-theme2: #fff;
  --navigation-bar-theme2-text-color: #000000;

  //border color theme2
  --border-color-theme2: #585858;

  //theme2 secondary color
  --theme2-secondary-color: #313131;
  --theme2-primary-color: #585858;
}

.dicksfreshmarket-theme {

  --primary-color: #000;
  --secondary-color: #f15850;
  --primary-light-color: #5ee397;
  --invert-secondary-color: #000;
  --gray-text-color: #000;
  --slider-text-h1-color: #000; //invert -secondary
  --slider-text-h2-color: #f15850; // secondary

  // add to cart button
  --add-to-card-bg-color: #5ee397;
  --add-to-card-font-color: #000;
}

.taazaflavors-theme {

  --invert-secondary-color: #000;
  --gray-text-color: #000;
  --primary-color: #679c32;
  --invert-primary-color: #83c240;
  --secondary-color: #83c240;
  --primary-light-color: #b3db8b;
  --bg-dark-black: #d3cf68;

  --slider-text-h1-color: #000; //invert -secondary
  --slider-text-h2-color: #83c240; // secondary
  --slider-text-p-color: #000; // black-text-color
}

.TheMarketsClipAndSave-theme {
  --primary-color: #8a3c27;
  --secondary-color: #4f6f18;
  --primary-light-color: #7edaae;
  --invert-primary-color: #8a3c27;
}

.IGAMarketStores-theme {
  --primary-color: #8a3c27;
  --secondary-color: #4f6f18;
  --primary-light-color: #7edaae;
  --invert-primary-color: #8a3c27;
}

.leekersfamilyfoods-theme {

  --invert-secondary-color: rgb(77, 70, 70);
  --gray-text-color: #000;
  --black-3: #e71d20;
  --primary-color: #d30404;
  --invert-primary-color: #d30404;
  --secondary-color: #1a1918;
  --primary-light-color: #eb5e5e;
  --cookie-background-color: #eb5e5e;
  --cookie-btn-background-color: #d30404;

  --slider-text-h1-color: rgb(77, 70, 70); //invert -secondary
  --slider-text-h2-color: #ffffff; // secondary

  // add to cart button
  --add-to-card-bg-color: #eb5e5e;
  --add-to-card-font-color: #d30404;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #d30404;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #1a1918;
  --coupon-text-color: #fff;

  // newsletter button
  --newsletter-btn-bg-color: #d30404;
  --newsletter-btn-font-color: #fff;
}

.CartersSuperMarkets-theme {
  --invert-primary-color: #d3423d;
  --primary-color: #d3423d;
  --secondary-color: #8c1b18;
  --bg-dark-color: #d3423d;
  --bg-dark-black: #d3423d;
  --primary-light-color: #e49b91;
  --slider-text-h1-color: #fff;
  --bg-primary-light: #e49b91;
  // add to cart button
  --add-to-card-bg-color: #e49b91;
  --add-to-card-font-color: #d3423d;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #8c1b18;
  --coupon-text-color: #fff;

  // newsletter button
  --newsletter-btn-bg-color: #8c1b18;
  --newsletter-btn-font-color: #fff;

}

.SuperCMart-theme {
  --primary-color: #ed1b24;
  --secondary-color: #000000;
  --primary-light-color: #e62d2ea8;
  --bg-primary-light: #e62d2ea8;

  // add to cart button
  --add-to-card-bg-color: #e62d2ea8;
  --add-to-card-font-color: #ed1b24;

  // cart page table title
  --cart-page-header-title-bg-color: #e62d2ea8;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #e62d2ea8;
  --checkout-page-message-font-color: #171717;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #e62d2ea8;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #000000;
  --coupon-text-color: #fff;

  // newsletter button
  --newsletter-btn-bg-color: #000;
  --newsletter-btn-font-color: #fff;
}


.tealsfreshmarket-theme {
  --primary-color: #ed1b24;
  --secondary-color: #000000;
  --primary-light-color: #e62d2ea8;
  --bg-primary-light: #e62d2ea8;

  // add to cart button
  --add-to-card-bg-color: #e62d2ea8;
  --add-to-card-font-color: #ed1b24;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #000000;
  --coupon-text-color: #fff;

  // newsletter button
  --newsletter-btn-bg-color: #fea133;
  --newsletter-btn-font-color: #fff;

}

.renfroesmarket-theme {
  --primary-color: #000000;
  --secondary-color: #b42127;
  --primary-light-color: #fd91a0;

  // add to cart button
  --add-to-card-bg-color: #e5fef2;
  --add-to-card-font-color: #13c487;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #000000;
  --coupon-text-color: #fff;

  // newsletter button
  --newsletter-btn-bg-color: #b42127;
  --newsletter-btn-font-color: #fff;
}


.VowellsMarketplace-theme {
  --primary-color: #056839;
  --secondary-color: #056839;
  --primary-light-color: #b8d8c4;

  // add to cart button
  --add-to-card-bg-color: #b8d8c4;
  --add-to-card-font-color: #0e5126;

  // cart page table title
  --cart-page-header-title-bg-color: #defff0;
  --cart-page-header-title-font-color: #171717;

  // checkout page message
  --checkout-page-message-bg-color: #defff0;
  --checkout-page-message-font-color: #13c487;

  // checkout page amount information
  --checkout-page-amount-info-bg-color: #defff0;
  --checkout-page-amount-info-font-color: #171717;

  // coupon tag lable
  --coupon-bg-color: #d42027;
  --coupon-text-color: #fff;

  // newsletter button
  --newsletter-btn-bg-color: #b42127;
  --newsletter-btn-font-color: #fff;

  --bg-extra-color-theme2: #dd1a32;
  --add-to-cart-coupon-button-bg-color: #056839;
  --breadcrumb-bg-color: #056839;
  --breadcrumb-text-color: #fff;
  --breadcrumb-sub-text-color: #fff;
  --bg-color-theme2: #dd1a32;
  --border-color-theme2: #dd1a32;
  --invert-primary-color: #dd1a32;
  --bg-dark-black: #056839;
}

.TerrysGrocery-theme {
  --primary-color: #0b0c0c;
  --secondary-color: #3874BD;
  --primary-light-color: #4d9ae8;
}

.WaynesPackAndSave-theme {
  --primary-color: #256115;
  --secondary-color: #40332d;
  --primary-light-color: #e9815e;
  --invert-secondary-color: #555ba7;
}

.LongfellowMarket-theme {
  --primary-color: #4a1900;
  --secondary-color: #4a1900;
  --primary-light-color: #d48a64;
  --invert-secondary-color: #3d1e0e;
}

.MountainMarket-theme {
  --primary-color: #d2cb93;
  --secondary-color: #3c522c;
  --primary-light-color: #3c522c;
  --invert-secondary-color: #bcc291;
}

.cainsmarket-theme {
  --primary-color: #D5993F;
  --secondary-color: #fea133;
  --primary-light-color: #f9f3ed;
  --slider-text-h1-color: #F3E5D9;
  --slider-text-h2-color: #1C1B18;
}

.DyerFoods-theme {
  --primary-color: #891414;
  --secondary-color: #f60106;
  --primary-light-color: #d59a9a;
  --gray-text-color: #f60106;
  --bg-primary-light: #ebb6ae;
}

.BrucePW-theme {
  --primary-color: #db0703;
  --secondary-color: #6264a6;
  --primary-light-color: #fdd2b1;
  --gray-text-color: #4f519e;
  --bg-primary-light: #fdd2b1;
}

.DublinPW-theme {
  --primary-color: #c92033;
  --secondary-color: #e0987e;
  --primary-light-color: #d8b976;
  --gray-text-color: #c92033;
  --bg-primary-light: #e0987e;
}

.parkstonfoodcenter-theme {
  --primary-color: #2d553a;
  --secondary-color: #871a13;
  --primary-light-color: #d0736c;
  --gray-text-color: #2d553a;
  --bg-primary-light: #d0736c;
}

.YossBros-theme {
  --primary-color: #0000fe;
  --secondary-color: #b7011a;
  --primary-light-color: #deadad;
  --gray-text-color: #0000fe;
  --bg-primary-light: #ffa3a3;
  --coupon-bg-color: #0000fe;
  --add-to-card-bg-color: #b7011a;
}

.WaltsFoods-theme {
  --primary-color: #140d0d;
  --secondary-color: #ff0000;
  --primary-light-color: #ec4a4a;
  --gray-text-color: #140d0d;
  --bg-primary-light: #ec4a4a;
  --coupon-bg-color: #140d0d;
  --add-to-card-bg-color: #ff0000;
  --invert-secondary-color: #1a1b1c;
  --cart-page-header-title-bg-color: #b0b0b0;
  --input-border-color: #000;
  --checkout-page-message-font-color: #000000;
  --bg-extra-color-theme2: #140d0d;
  --add-to-cart-coupon-button-bg-color: #ec4a4a;
  --border-color-theme2: #140d0d;
  --reward-redeem-btn-bg-color: #140d0d;
  --reward-redeem-btn-font-color: #fff;
  --reward-view-btn-bg-color: #140d0d;
  --reward-view-btn-font-color: #fff;

  --theme2-coupon-remove-cart-bg-color: #fff;
  --theme2-coupon-remove-cart-font-color: #000;
  // --theme2-coupon-remove-cart-bg-color-hover: #a80727;
  --bg-color-theme2: #ec1c24;
  --breadcrumb-text-color: #fff;
  --product-tag-bg-color: #ec1c24;
  --bg-dark-black: #e91b26;
}

.PtaceksIGA-theme {
  --bg-extra-color-theme2: #464e2e;
  --add-to-cart-coupon-button-bg-color: #464e2e;
  --primary-color: #464e2e;
  --secondary-color: #878686;
  --breadcrumb-bg-color: #464e2e;
  --breadcrumb-text-color: #fff;
  --breadcrumb-sub-text-color: #fff;
  --bg-color-theme2: #464e2e;
  --border-color-theme2: #464e2e;
  --invert-primary-color: #464e2e;
  --product-tag-bg-color: var(--secondary-color);
}

.superiorgrocers-theme {
  --bg-extra-color-theme2: #ed1c24;
  --add-to-cart-coupon-button-bg-color: #ed1c24;
  --primary-color: #ed1c24;
  --secondary-color: #EAD196;
  --breadcrumb-bg-color: #ed1c24;
  --breadcrumb-text-color: #fff;
  --breadcrumb-sub-text-color: #fff;
  --bg-color-theme2: #ed1c24;
  --border-color-theme2: #ed1c24;
}

.MobilePW-theme {
  --bg-extra-color-theme2: #df2323;
  --add-to-cart-coupon-button-bg-color: #df2323;
  --primary-color: #df2323;
  --secondary-color: #fcac1f;
  --breadcrumb-bg-color: #df2323;
  --breadcrumb-text-color: #fff;
  --breadcrumb-sub-text-color: #fff;
  --bg-color-theme2: #df2323;
  --invert-primary-color: #df2323;
  --border-color-theme2: #df2323;
}

.vicentessupermarket-theme {
  --bg-extra-color-theme2: #61a749;
  --add-to-cart-coupon-button-bg-color: #ed1c24;
  --primary-color: #61a749;
  --secondary-color: #EAD196;
  --breadcrumb-bg-color: #ed1c24;
  --breadcrumb-text-color: #fff;
  --breadcrumb-sub-text-color: #fff;
  --bg-color-theme2: #61a749;
  --border-color-theme2: #ed1c24;
  --invert-primary-color: #61a749;
}

.MathernesMarket-theme {
  --bg-extra-color-theme2: #000000;
  --add-to-cart-coupon-button-bg-color: #000000;
  --primary-color: #000000;
  --secondary-color: #000000;
  --breadcrumb-bg-color: #000000;
  --breadcrumb-text-color: #ffffff;
  --breadcrumb-sub-text-color: #ffffff;
  --bg-color-theme2: #000000;
}