.reward-banner-wrap {
    margin-bottom: 30px;

    @media (max-width: 575px) {
        margin-bottom: 20px;
    }

    .reward-banner-content {
        display: flex;
        align-items: center;
        margin: 0 -15px;

        @media (max-width: 992px) {
            display: block;
            margin: 0;
        }

        .reward-left-area {
            width: 80%;
            padding: 0 15px;

            @media (max-width: 992px) {
                width: 100%;
                padding: 0;
            }

            .reward-main-wrap {
                .reward-box {
                    background-color: var(--bg-color-theme2);
                    border-radius: 10px;
                    padding: 20px;

                    @media (max-width: 575px) {
                        padding: 15px;
                    }

                    @media (min-width: 767px) {
                        display: flex;
                        align-items: center;
                    }

                    .reward-img {
                        background: #fff;
                        border-radius: 10px;
                        margin: 0;
                        min-height: 180px;
                        padding: 10px;
                        text-align: center;
                        width: 33.33%;
                        margin: 0 15px 0 0;

                        img {
                            max-height: 200px;
                        }

                        @media (max-width: 767px) {
                            width: 100%;
                            margin: 0 0 15px 0;
                        }
                    }

                    .reward-content-wrapper {
                        width: 66.66%;

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        color: #fff;

                        .reward-subtitle {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 1.2;
                        }

                        .reward-title-btn {
                            margin: 15px 0;

                            @media (min-width: 992px) {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }

                            @media (max-width: 1400px) {
                                flex-wrap: wrap;
                            }

                            .reward-title {
                                font-size: 20px;
                                font-weight: 500;
                                line-height: normal;

                                @media (min-width: 650px) {
                                    max-width: 80%;
                                }

                                @media (max-width: 1200px) {
                                    font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                                }

                                @media (max-width: 1400px) {
                                    margin-bottom: 10px;
                                }
                            }

                            .reward-btn {
                                cursor: pointer;
                                text-transform: capitalize;
                                background: var(--theme2-secondary-color);
                                border-radius: 40px;
                                color: #fff;
                                padding: 10px 20px;
                                width: max-content;
                                text-align: center;
                            }
                        }
                    }

                    .reward-range-bar {
                        @media (min-width: 575px) {
                            width: 100%;
                        }

                        .progress-ba {
                            progress {
                                appearance: none;
                                width: 100%;
                                height: 40px !important;
                                background-color: #EFEFEF;
                                border-radius: 50px;

                                &::-webkit-progress-bar {
                                    background-color: #EFEFEF;
                                    border-radius: 50px;
                                }

                                &::-webkit-progress-value {
                                    background-color: #FECE00;
                                    border-radius: 50px 0 0 50px;
                                }

                                &::-moz-progress-bar {
                                    background-color: #FECE00;
                                    border-radius: 50px 0 0 50px;
                                }
                            }
                        }

                        .progress-bar {
                            height: 30px;
                            display: -ms-flexbox;
                            display: flex;
                            overflow: hidden;
                            line-height: 0;
                            font-size: .75rem;
                            background-color: #e9ecef;
                            border-radius: 0.25rem;

                            @media (max-width: 575px) {
                                height: 25px;
                            }

                            .progress-bar-line {
                                background-color: var(--theme2-secondary-color);
                            }

                            progress {
                                height: 60px;
                                width: 436px;
                            }
                        }

                        .reward-range {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
    }

    .reward-right-area {
        width: 20%;
        padding: 0 15px;

        @media (max-width: 992px) {
            width: 100%;
            padding: 0;
            margin-top: 15px;
            text-align: center;
        }
    }
}