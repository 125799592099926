.loyalty-reward-main-wrap {
    .loyalty-reward-box {
        background-color: #edf2ee;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 50px;
        @media (max-width: 575px) {
            padding: 15px;
        }
        @media (min-width: 767px) {
            display: flex;
            align-items: center;
        }
        .loyalty-reward-img {
            background: #fff;
            border-radius: 10px;
            margin: 0;
            min-height: 180px;
            padding: 10px;
            text-align: center;
            width: 33.33%;
            margin: 0 15px 0 0;
            img {
                max-height: 200px;
            }
            @media (max-width: 767px) {
                width: 100%;
                margin: 0 0 15px 0;
            }
        }
        .loyalty-reward-content-wrapper {
            width: 66.66%;
            @media (max-width: 767px) {
                width: 100%;
            }
            color: #000;
            .loyalty-reward-subtitle {
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 500;
                color: var(--primary-color);
                line-height: 1.2;
            }
            .loyalty-reward-title-btn {
                margin-bottom: 10px;
                .loyalty-reward-title {
                    margin: 10px 0;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: normal;
                    @media (max-width: 1200px) {
                        font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                    }
                    @media (max-width: 992px) {
                        margin-bottom: 10px;
                    }
                }
                .loyalty-reward-point-btn {
                    cursor: pointer;
                    text-transform: capitalize;
                    background: var(--theme2-secondary-color);
                    width: max-content;
                    text-align: center;
                    border-radius: 5px;
                    color: #000;
                    padding: 3px 10px;
                }
            }
        }
        .loyalty-reward-range-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 650px) {
               flex-wrap: wrap;
               gap: 10px;
              }
            .reward-btn-wrapper{
                display: flex;
                align-items: center;
            }
            .loyalty-reward-point {
                font-size: 18px;
                color: var(--primary-color);
                font-weight: 600;
            }
            .reward-btn {
                cursor: pointer;
                text-transform: capitalize;
                background: var(--reward-redeem-btn-bg-color);
                border-radius: 40px;
                color: var(--reward-redeem-btn-font-color);
                padding: 10px 20px;
                width: max-content;
                text-align: center;
                &.redeem-btn{
                    margin-left: 10px;
                }
            }
        }
    }
}