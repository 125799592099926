@import "styles/mixins/index";
.order-section-theme2 {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  @include breakpoint("max-sm") {
    margin-top: 20px;
  }
  .order-page-title {
    border-bottom: 1px solid #ddd;
    padding: 20px;
    @include flex-between;
    @include breakpoint("max-sm") {
      padding: 15px;
    }
    h1 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      color: var(--invert-secondary-color);
      @include breakpoint("max-sm") {
        font-size: 14px;
      }
    }
  }
  .order-section-main-wrapper {
    padding: 20px;
    .order-section-wrapper {
      .order-section-items {
        margin: 0 0 15px;
        &:last-child {
          margin: 0;
        }
        a {
          border: 1px solid #e6e6e6;
          border-radius: 10px;
          padding: 15px;
          @include flex-between;
          @media (max-width: 767px) {
            display: block;
          }
          .order-items {
            @media (min-width: 767px) {
              width: calc(100% - 300px);
            }
            .order-title {
              color: #000;
              font-size: 15px;
              font-weight: 600;
              margin: 0 0 10px;
            }
            .order-product-image {
              display: flex;
              margin: 0 0 10px;
              .order-image {
                align-items: center;
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50px;
                display: inline-block;
                display: inline-flex;
                height: 40px;
                justify-content: center;
                margin: 0 -15px 0 0;
                overflow: hidden;
                width: 40px;
                img {
                  max-width: 30px;
                }
              }
            }
            .order-number-id {
              .order-num-title {
                color: #999;
                font-size: 15px;
                font-weight: 500;
                margin: 0 0 10px;
                span {
                  color: #000;
                }
              }
            }
            .order-product-month {
              color: #4d4d4d;
              font-size: 15px;
              font-weight: 500;
              span {
                margin: 0 0 0 15px;
                padding: 0 0 0 20px;
                position: relative;
                &::before {
                  background-color: #4d4d4d;
                  border-radius: 50px;
                  content: "";
                  height: 5px;
                  left: 0;
                  position: absolute;
                  top: 8px;
                  width: 5px;
                }
              }
            }
          }
          .order-price-section {
            @include flex-between;
            @media (min-width: 767px) {
              width: 300px;
            }
            @media (max-width: 767px) {
              margin-top: 15px;
            }
            span {
              color: #007bff;
            }
            .price {
              color: #000;
              font-size: 15px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}