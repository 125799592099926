@import "styles/mixins/index";

.bestseller-slider {
  // padding: map-get($pixel, md) * 2 0;
  padding-bottom: map-get($pixel, md) * 2;

  @include breakpoint("max-sm") {
    padding: 0 0 20px 0;
  }

  .slick-slide {
    padding: 0 15px;
    @media (max-width: 575px) {
      padding: 0 13px;
    }
  }

  .slick-list {
    margin: 0 -15px;

    @include breakpoint("max-md") {
      margin: 0 -20px;
    }

    @include breakpoint("max-md") {
      margin: 0 -12px;
    }
  }

  .left-arrow-alignment {
    position: absolute;
    right: 42px;
    top: -63px;

    @include breakpoint("max-sm") {
      top: -45px;
    }
  }

  .right-arrow-alignment {
    position: absolute;
    right: 0;
    top: -63px;

    @include breakpoint("max-sm") {
      top: -45px;
    }

    svg {
      transform: rotate(178deg);
    }
  }

  .sellerpage-title {
    margin: 20px 0 15px;
    position: relative;
    display: flex;
    align-items: center;

    h1 {
      color: #1a1a1a;
      font-size: 30px;
      font-weight: 500;
      margin-right: 15px;

      @media (max-width: 1200px) {
        font-size: calc(18px + (25 - 18) * ((100vw - 280px) / (1200 - 280)));
      }
    }

    p {
      @include heading-2;
      margin: 0 0 map-get($pixel, md) * 1-6 0;
      color: var(--black-text-color);

      @include breakpoint("max-sm") {
        @include heading-7;
      }
      @media (max-width: 1200px) {
        line-height: normal;
        font-size: calc(15px + (25 - 15) * ((100vw - 280px) / (1200 - 280)));
      }
    }

    span {
      font-family: var(--heading-font-family), var(--default-font-family);
      color: var(--gray-400);
      @media (max-width: 1200px) {
        font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
      }
    }

    a {
      // position: absolute;
      // right: 0;
      // bottom: -14px;
      font-size: 14px;
      border-bottom: 2px #2a2a2a solid;
      vertical-align: middle;
      font-family: var(--heading-font-family), var(--default-font-family);
      // margin: -58px 0 0 0;
      font-weight: 400;
      // @include breakpoint("max-sm") {
      //   bottom: -18px;
      // }
    }

    a:hover {
      color: var(--secondary-color);
    }
  }

  .seller-card {
    background-color: var(--bg-white);
    padding: map-get($pixel, md) map-get($pixel, md) * 2 map-get($pixel, md) * 2 map-get($pixel, md) * 2;
    box-shadow: 0px 0px 15px rgba(129, 129, 129, 0.16);
    @include normal-radius;
    @include pointer;
    border: 1px solid var(--border-color-4);
    @include ease-in-out;
    min-height: 211px;

    &:hover {
      border: 1px solid var(--primary-color);
    }

    .sellercard-details {
      padding: map-get($pixel, sm) + 2px 0 0 0;

      p {
        @include text-bold;
        color: var(--black-text-color);
        @include text-center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .sellercard-image {
      @include flex-center;
      justify-content: map-get($layout, center);

      img {
        max-width: 135px;
        max-height: 128px;
        height: 128px;
        object-fit: cover;
        padding: 15px;
      }
    }
  }

  .custom-arrow-design {
    width: 32px;
    border-radius: 50%;
    background-color: var(--bg-light-400);
    @include pointer;
    height: 32px;
    @include flex-center;
    justify-content: map-get($layout, center);
    @include ease-in-out;
    color: var(--gray-text-color);

    &:hover {
      background-color: var(--primary-color);
      color: var(--bg-white);
    }
  }

  .deal-all-product {
    height: 100%;
    position: relative;

    .deal-all-product-main-wrapper {
      font-family: var(--text-font-family);
      height: 100%;
      position: relative;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 10px 10px 15px;
      outline: 3px solid transparent;
      outline-offset: -3px;
      overflow: hidden;
      transition: all 0.6s ease-in-out;

      &:hover {
        border-color: var(--border-color-theme2);
        outline-color: var(--border-color-theme2);
      }

      .deal-right-product-wrap {
        height: 100%;

        .deal-right-product-main-wrap {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          position: relative;
          overflow: hidden;

          .offer-tag-quickview {
            position: absolute;
            top: 0;
            left: 0;
            right: auto;
            bottom: auto;
            width: 100%;
            z-index: 11;
            display: flex;
            justify-content: space-between;
            .offer-product-tag {
              .product-tag {
                min-height: 36px;
                border-radius: 4px;
                color: #fff;
                font-size: 13px;
                font-weight: 400;
                background: var(--product-tag-bg-color);
                padding: 8px 10px;
                display: inline-block;
              }
            }
          }

          .deal-right-product-img {
            margin: 45px 0 0;
            text-align: center;

            a {
              img {
                min-height: 210px;
                max-height: 210px;
                max-width: 210px;
                object-fit: contain;
                margin: auto;
                -webkit-transition: all 0.6s ease-in-out;
                -o-transition: all 0.6s ease-in-out;
                transition: all 0.6s ease-in-out;
              }
            }

            .offer-product-list-icon {
              text-align: right;
              position: relative;
              display: flex;
              justify-content: end;

              .add-to-list-icon {
                border: 1px solid #bfbfbf;
                background-color: #fff;
                box-shadow: 0 0 6px rgba(0, 0, 0, .1);
                -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, .1);
                border-radius: 50%;
                display: inline-block;
                line-height: 2.8;
                width: 35px;
                height: 35px;
                text-align: center;
                position: relative;
                cursor: pointer;
                z-index: 11;
                transition: all 0.5s ease-in-out;

                svg {
                  transition: all 0.5s ease-in-out;
                }

                .list-tooltip {
                  position: absolute;
                  bottom: auto;
                  display: inline-block;
                  padding: 3px 6px;
                  color: #000;
                  font-size: 12px;
                  z-index: 1;
                  line-height: normal;
                  left: -39px;
                  white-space: nowrap;
                  right: 0;
                  top: -28px;
                  margin: auto;
                  width: max-content;
                  background-color: #fff;
                  border-radius: 4px;
                  display: none;
                  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
                }

                &:hover .list-tooltip {
                  display: block;
                }

                &:hover {
                  background-color: var(--bg-color-theme2);

                  svg {
                    path {
                      fill: #fff;
                    }
                  }
                }
              }
            }
          }

          .deal-right-product-caption {
            font-family: var(--text-font-family);
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 5px;

            .card-price-title {
              width: 70%;

              .product-title {
                height: 40px;
                min-height: 44px;
                font-family: var(--text-font-family);
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                color: #4d4d4d;
                display: -webkit-box;
                font-size: 13px;
                font-weight: 400;
                overflow: hidden;
              }

              .product-price-shipping {
                font-size: 13px;
                font-weight: 800;
                color: #000000;
                display: inline-block;
                vertical-align: middle;
                letter-spacing: 0;
              }

              .regular-price {
                margin-left: 7px;
                font-size: 13px;
                font-weight: 500;
                text-decoration: line-through;
                color: #999;
                letter-spacing: .3px;
                display: inline-block;
              }
            }

            .bag-icon {
              border: 1px solid #bfbfbf;
              background-color: var(--primary-color);
              border-radius: 50%;
              position: relative;
              cursor: pointer;
              display: inline-block;
              height: 38px;
              width: 38px;
              line-height: 29px;
              text-align: center;
              line-height: 2.8;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
              -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
              transition: all .5s ease-in-out;

              svg {
                transition: all .5s ease-in-out;
              }

              &:hover {
                background-color: var(--bg-color-theme2);
              }

              img {
                transition: all .5s ease-in-out;
              }

              &:hover {
                svg {
                  transform: rotate(360deg);

                  path {
                    fill: #fff;
                  }
                }
              }

              svg {
                path {
                  transition: all .5s ease-in-out;
                  fill: #fff;
                }
              }

              .cart-tooltip {
                position: absolute;
                bottom: auto;
                display: inline-block;
                padding: 3px 6px;
                color: #000;
                font-size: 12px;
                z-index: 1;
                line-height: normal;
                left: -45px;
                white-space: nowrap;
                right: 0;
                top: -28px;
                margin: auto;
                width: max-content;
                background-color: #fff;
                border-radius: 4px;
                display: none;
                box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
              }

              &:hover .cart-tooltip {
                display: block;
              }
            }

            .increment-decrement-qty {
              align-items: center;
              border: 1px solid var(--border-color-theme2);
              border-radius: 20px;
              display: flex;
              height: 35px;
              justify-content: space-between;
              max-width: 85px;
              padding: 4px;
              text-align: center;
              width: 90px;

              button {
                border: 1px solid #f2f2f2;
                color: #fff;
                background-color: var(--border-color-theme2);
                border-radius: 50%;
                height: 25px;
                width: 25px;
                font-size: 14px;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: #000;
                  color: #fff;
                }
              }

              span {
                border: none;
                height: auto;
                line-height: 37px;
                width: 38px;
              }
            }

            .load-icon {
              text-align: center;
              display: inline-block;
              height: 38px;
              width: 38px;
              border-radius: 50%;
              line-height: 3.1;

              img {
                mix-blend-mode: multiply;
                height: 25px;
                width: 25px;
              }
            }
          }

          .coupon-used-time-ribbon {
            border: 1px solid #f2f2f2;
            box-shadow: 0 0 6px rgba(0, 0, 0, .1);
            -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, .1);
            border-radius: 50%;
            display: inline-block;
            line-height: 2.8;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 2.2;
          }
        }
      }

      &:hover {
        .deal-right-product-img {
          a {
            img {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    &:hover {
      .deals-offer-text {
        display: block;
        height: 46px;
        opacity: 1;
      }

      .card-price-title {
        .product-title {
          color: var(--product--theme2-text-color) !important;
        }
      }
    }

    .deals-offer-text {
      background-color: var(--product-tag-bg-color);
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      height: 0;
      opacity: 0;
      padding: 7px;
      transition: all .5s ease-in-out;

      p {
        font-size: 12px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        padding: 0;
      }
    }
  }
}

.bestseller-slider-no-bottom-space {
  padding-bottom: 0;
}

.bestseller-slider {
  &.features-product-card {
    .sellerpage-title {
      display: block;
    }
  }
}